import { ca } from '@cian/analytics';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IPageExtra } from '../../../types';
import { IPromoInfo } from '../../../types/newbuildingPromos';
import { getProducts } from '../../../utils/tracking/get_products';
import { ISoprProducts } from '../../../utils/tracking/types';

interface ITrackMortgageClick {
  promo: IPromoInfo;
  offer: IOfferSchema;
}

export function trackMortgageClick({ promo, offer }: ITrackMortgageClick) {
  const extra: Partial<IPageExtra> = {
    promoCianMortgage: promo.uniquePromoId,
    promoId: promo.promoId,
  };

  const products: ISoprProducts = getProducts({ agent: null, offer, kpId: null, extra });

  ca('eventSite', {
    name: 'oldevent',
    action: 'click_morgage',
    category: 'Promo',
    label: promo.promoType,
    products: [products],
  });
}
