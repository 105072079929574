import * as React from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import {
  trackBookingRequestError,
  trackBookingRequestSuccess,
} from 'shared/containers/NewbuildingBookingForm/tracking';
import { selectOfferId } from 'shared/selectors';
import { getUserName, selectDefaultPhoneNumber } from 'shared/selectors/callbackRightNow';
import { developerNameSelector, developerIdSelector } from 'shared/selectors/developer';
import { getGaLabel } from 'shared/selectors/newObject';
import { getNewbuildingId, getNewbuildingName } from 'shared/selectors/newbuilding';
import { regionIdSelector } from 'shared/selectors/offerData/geo/regionIdSelector';
import { fetchBookingRequest } from 'shared/services/newbuildingBooking';
import { getPrivacyPolicyUrl } from 'shared/utils/newbuildingRentBanner/getPrivacyPolicyUrl';

type TBookingFormStatus = 'success' | 'error' | 'default';

interface IUseBookingFormResult {
  personalAgreementLink: string;
  name: string;
  nameError: boolean;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phone: string;
  phoneError: boolean;
  onPhoneChange: (value: string) => void;
  submitting: boolean;
  onSubmit(): void;
  onRetry(): void;
  formStatus: TBookingFormStatus;
}

export const useBookingForm = (): IUseBookingFormResult => {
  const { config, httpApi, logger } = useApplicationContext();

  const personalAgreementLink = React.useMemo(() => {
    return getPrivacyPolicyUrl(config);
  }, [config]);

  const defaultPhoneNumber = useSelector(selectDefaultPhoneNumber) || '';
  const defaultUserName = useSelector(getUserName);
  const gaLabel = useSelector(getGaLabel);
  const id = useSelector(selectOfferId);
  const regionId = useSelector(regionIdSelector);
  const developerId = useSelector(developerIdSelector);
  const developerName = useSelector(developerNameSelector);
  const newbuildingId = useSelector(getNewbuildingId);
  const newbuildingName = useSelector(getNewbuildingName);

  const [formStatus, setFormStatus] = React.useState<TBookingFormStatus>('default');
  const [name, setName] = React.useState(defaultUserName);
  const [phone, setPhone] = React.useState(defaultPhoneNumber);
  const [submitting, setSubmitting] = React.useState(false);
  const [phoneError, setPhoneError] = React.useState(false);
  const [nameError, setNameError] = React.useState(false);

  const onNameChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value as string);
    setNameError(false);
  }, []);

  const onPhoneChange = React.useCallback((value: string) => {
    setPhone(value);
    setPhoneError(false);
  }, []);

  const onRetry = React.useCallback(() => {
    setFormStatus('default');
  }, []);

  const onSubmit = React.useCallback(async () => {
    if (phone.length < 10) {
      setPhoneError(true);

      return;
    }

    if (name.length < 2) {
      setNameError(true);

      return;
    }

    setSubmitting(true);

    const result = await fetchBookingRequest({
      httpApi,
      logger,
      parameters: {
        userName: name,
        phone: `+7${phone}`,
        offerId: id,
        regionId,
        developer: {
          id: developerId as number,
          name: developerName as string,
        },
        newbuilding: {
          id: newbuildingId as number,
          name: newbuildingName,
        },
      },
    });

    if (result.success) {
      trackBookingRequestSuccess({ gaLabel, id });
      setFormStatus('success');
    } else {
      trackBookingRequestError({ gaLabel, id });
      setFormStatus('error');
    }

    setSubmitting(false);
  }, [phone, name, id, regionId, developerId, developerName, newbuildingId, newbuildingName, gaLabel]);

  return React.useMemo(
    () => ({
      personalAgreementLink,
      name,
      nameError,
      onNameChange,
      phone,
      phoneError,
      onPhoneChange,
      submitting,
      onSubmit,
      onRetry,
      formStatus,
    }),
    [
      personalAgreementLink,
      name,
      nameError,
      onNameChange,
      phone,
      phoneError,
      onPhoneChange,
      submitting,
      onSubmit,
      formStatus,
      onRetry,
    ],
  );
};
