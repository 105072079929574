import { IChatHintSchema } from 'shared/repositories/chats/entities/chat_hint/ChatHintSchema';

import { EAuthenticationActionType, TAuthenticationActions } from '../../actions/authentication';
import { TChatAction, EChatsActionTypes } from '../../actions/chat';

export interface IOfferChatsState {
  isChatsEnabled: boolean;
  isChatOpened: boolean;
  openAfterAuth: boolean;
  isOnline: boolean;
  chatId?: string;
  hintQuestions: IChatHintSchema[];
  type?: 'open_phone' | 'open_message' | 'open_chatbot';
  defaultMessage?: string;
  shouldSendDefaultMessage?: boolean;
  source?: string;
  messagePayload?: string;
}

const initialState: IOfferChatsState = {
  chatId: undefined,
  isChatsEnabled: false,
  isChatOpened: false,
  openAfterAuth: false,
  isOnline: false,
  hintQuestions: [],
  defaultMessage: undefined,
  shouldSendDefaultMessage: undefined,
};

export function chatReducer(
  state: IOfferChatsState = initialState,
  action: TChatAction | TAuthenticationActions,
): IOfferChatsState {
  switch (action.type) {
    case EChatsActionTypes.StateReceived:
      return {
        ...state,
        isChatsEnabled: action.payload.enabled,
      };

    case EChatsActionTypes.OfferChatReceived:
      return {
        ...state,
        chatId: action.payload.chatId,
      };

    case EChatsActionTypes.OnlineStatusReceived:
      return {
        ...state,
        isOnline: action.payload.isOnline,
      };

    case EChatsActionTypes.HintQuestionsReceived:
      return {
        ...state,
        hintQuestions: action.payload.hintQuestions,
      };

    case EChatsActionTypes.OpenChat:
      return {
        ...state,
        isChatOpened: true,
        type: action.payload.type,
        defaultMessage: action.payload.defaultMessage,
        shouldSendDefaultMessage: action.payload.shouldSendDefaultMessage,
        source: action.payload.source,
        messagePayload: action.payload.messagePayload,
      };

    case EChatsActionTypes.OpenChatbot:
      return {
        ...state,
        isChatOpened: true,
        defaultMessage: action.payload.defaultMessage,
        type: 'open_chatbot',
      };

    case EChatsActionTypes.CloseChat:
      return {
        ...state,
        isChatOpened: false,
        type: undefined,
        defaultMessage: undefined,
        shouldSendDefaultMessage: undefined,
        source: undefined,
        messagePayload: undefined,
      };

    case EChatsActionTypes.OpenChatAfterAuth:
      return {
        ...state,
        openAfterAuth: true,
        type: action.payload.type,
        defaultMessage: action.payload.defaultMessage,
        shouldSendDefaultMessage: action.payload.shouldSendDefaultMessage,
        source: action.payload.source,
        messagePayload: action.payload.messagePayload,
      };

    case EAuthenticationActionType.Succeed: {
      if (!state.openAfterAuth) {
        return state;
      }

      return {
        ...state,
        isChatOpened: true,
        openAfterAuth: false,
      };
    }

    case EAuthenticationActionType.Cancelled:
      return {
        ...state,
        openAfterAuth: false,
      };

    default:
      return state;
  }
}
