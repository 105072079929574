import { Button, Input, InputAdornment, MaskedInput, UIHeading2, UIText1, UIText2, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './NewbuildingBookingForm.css';

interface INewbuildingBookingFormComponentProps {
  personalAgreementLink: string;
  name: string;
  nameError: boolean;
  onNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  phone: string;
  phoneError: boolean;
  onPhoneChange: (value: string) => void;
  submitting: boolean;
  onSubmit(): void;
}

export const NewbuildingBookingFormComponent = ({
  personalAgreementLink,
  name,
  nameError,
  onNameChange,
  phone,
  phoneError,
  onPhoneChange,
  submitting,
  onSubmit,
}: INewbuildingBookingFormComponentProps) => {
  return (
    <div className={style['form']}>
      <div className={style['heading']}>
        <UIHeading2>Заявка на бронирование</UIHeading2>
        <UIText1>Менеджер Циан свяжется с&nbsp;вами для уточнения условий бронирования</UIText1>
      </div>
      <div className={style['input-group']}>
        <div className={style['input']}>
          <UIText2>Имя</UIText2>
          <Input value={name} onChange={onNameChange} size="M" disabled={submitting} invalid={nameError} />
        </div>
        <div className={style['input']}>
          <UIText2>Телефон</UIText2>
          <MaskedInput
            disabled={submitting}
            mask="(000) 000 00-00"
            leftAdornment={<InputAdornment>+7</InputAdornment>}
            value={phone}
            onChange={onPhoneChange}
            invalid={phoneError}
            size="M"
            width={251}
            lazy
          />
        </div>
      </div>
      <div className={style['agreement']}>
        <UIText3 color="text-secondary-default">
          Нажимая на кнопку, вы соглашаетесь на обработку персональных данных в соответствии с{' '}
          <a href={personalAgreementLink} className={style['agreement-link']} target="_blank" rel="noreferrer">
            Политикой конфиденциальности
          </a>
        </UIText3>
      </div>
      <div className={style['controls']}>
        <Button size="L" onClick={onSubmit} loading={submitting}>
          Отправить заявку
        </Button>
      </div>
    </div>
  );
};
