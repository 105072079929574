import { EChatsActionTypes, TChatAction } from 'shared/actions/chat';

import { IChatOnboarding } from '../../types/chatOnboarding';

const defaultState = {
  wasChatOpened: false,
};

// TODO: Удалить в CD-226224.Онбординги на месяц.
export function chatOnboardingReducer(state: IChatOnboarding = defaultState, action: TChatAction) {
  switch (action.type) {
    case EChatsActionTypes.OpenChat:
    case EChatsActionTypes.OpenChatAfterAuth:
      return {
        ...state,
        wasChatOpened: true,
      };
    default:
      return state;
  }
}
