import { ErrorLogComponent } from '@cian/error-log-component';
import { QRCodeCanvas, QRCodeImg } from '@cian/frontend-qrcode';
import { CustomTooltip, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { trackViewQRCode } from 'shared/components/PhoneLinkQRCode/analytics';
import { useApplicationContext } from 'shared/containers/ApplicationContext';

import * as styles from './PhoneLinkQRCode.css';

interface IQRCodePhone {
  phoneLink: string;
  staticPreview?: boolean;
}

const COLOR_QR_CANVAS = {
  dark: '#152242',
  light: '#ffffff',
};

const COLOR_QR_PREVIEW = {
  dark: '#152242',
  light: '#0000000f',
};

export const PhoneLinkQRCode = ({ phoneLink, staticPreview }: IQRCodePhone) => {
  const { logger } = useApplicationContext();
  const anchorRef = React.useRef(null);
  const [open, setOpenState] = React.useState(false);
  const [openedOnce, setOpenedOnce] = React.useState(false);

  const setOpen = React.useCallback(() => {
    setOpenState(true);

    if (!openedOnce) {
      trackViewQRCode();
      setOpenedOnce(true);
    }
  }, [setOpenState, trackViewQRCode, openedOnce, setOpenedOnce]);
  const setClose = React.useCallback(() => setOpenState(false), [setOpenState]);

  return (
    <ErrorLogComponent logger={logger}>
      <CustomTooltip
        open={open}
        placement="left"
        maxWidth={190}
        anchorRef={anchorRef}
        content={
          <div data-testid="phoneLinkQrCode" className={styles['container']}>
            <div className={styles['code']}>
              <QRCodeCanvas id="phoneLinkQrCode" width={166} text={phoneLink} color={COLOR_QR_CANVAS} />
            </div>
            <UIText2 color="black_60">Просто наведите камеру, чтобы набрать номер</UIText2>
          </div>
        }
        closeButton={false}
        onMouseLeave={setClose}
      />
      {staticPreview && (
        <div
          data-testid="qrCodeImg"
          className={styles['static-preview']}
          onMouseEnter={setOpen}
          onMouseLeave={setClose}
          ref={anchorRef}
        />
      )}
      {!staticPreview && (
        <div
          data-testid="qrCodeImg"
          className={styles['preview']}
          onMouseEnter={setOpen}
          onMouseLeave={setClose}
          ref={anchorRef}
        >
          <QRCodeImg scale={1} text={phoneLink} color={COLOR_QR_PREVIEW} width={26} />
        </div>
      )}
    </ErrorLogComponent>
  );
};
