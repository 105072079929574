import { MORTGAGE_PROMO_TYPES } from '../../../constants/newbuildingPromos';
import { EPromoType, IPromoInfo } from '../../../types/newbuildingPromos';

export const getAuthorDescription = (promo: IPromoInfo): string | null => {
  if (promo.promoType === EPromoType.CianMortgage) {
    return null;
  }

  const isMortgage = MORTGAGE_PROMO_TYPES.includes(promo.promoType);
  const promoLabel = isMortgage ? 'ипотечном' : 'акционном';
  const agentLabel = promo.isFromBuilder ? 'застройщика' : 'представителя застройщика';

  return `Узнайте у ${agentLabel} подробности об ${promoLabel} предложении`;
};
