import { ca } from '@cian/analytics';

interface ITrackBookingFormOpenParams {
  gaLabel: string;
  id: number;
}

export function trackBookingFormOpen({ gaLabel, id }: ITrackBookingFormOpenParams) {
  ca('eventSite', {
    action: 'Booking_popup_open_floating_block',
    category: 'Card',
    label: gaLabel,
    name: 'oldevent',
    products: [{ id }],
  });
}

export function trackBookingButtonShown(id: number) {
  ca('eventSite', {
    action: 'Show_sopr',
    category: 'Card',
    label: 'booking_button',
    name: 'oldevent',
    products: [{ id }],
  });
}
