import * as React from 'react';

import { getAuthorDescription } from 'shared/containers/NewbuildingPromoDetails/helpers';
import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { ActionButtonContainer } from './parts/ActionButtonContainer';
import { PromoDetailsModal } from '../../components/NewbuildingPromos/parts/PromoDetailsModal';
import { IPromoInfo } from '../../types/newbuildingPromos';
import { getCianMortgagePromoUrl } from '../../utils/motgageCalculator/getCianMortgagePromoUrl';
import { trackMortgageClick } from '../../utils/tracking/promos/trackMortgageClick';
import { useApplicationContext } from '../ApplicationContext';

export interface INewbuildingPromoDetailsContainerProps {
  offer: IOfferSchema;
  open: boolean;
  onClose: () => void;
  promo: IPromoInfo;
}

export const NewbuildingPromoDetailsContainer: React.FC<
  React.PropsWithChildren<INewbuildingPromoDetailsContainerProps>
> = ({ open, offer, onClose, promo }) => {
  const { config } = useApplicationContext();
  const mortgagePromoUrl = getCianMortgagePromoUrl(config);

  const handleCianMortgageClick = React.useCallback(() => {
    trackMortgageClick({ promo, offer });
  }, [promo, offer]);

  return (
    <PromoDetailsModal
      authorDescription={getAuthorDescription(promo)}
      contactsButton={
        <ActionButtonContainer
          mortgagePromoUrl={mortgagePromoUrl}
          onMortgageClick={handleCianMortgageClick}
          offer={offer}
          promo={promo}
          dataTestId="PromoDetailsModalCallButton"
        />
      }
      open={open}
      bank={promo.bank}
      banks={promo.banks}
      description={promo.description}
      features={promo.features}
      mortgagePartnerLink={mortgagePromoUrl}
      title={promo.name}
      authorLogoUrl={promo.authorLogoUrl}
      authorName={promo.authorName}
      onMortgageClick={handleCianMortgageClick}
      onClose={onClose}
    />
  );
};
