import { TColors } from '@cian/ui-kit-design-tokens/colors';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './IconPolice16.css';

interface IIconPolice16Props {
  color?: TColors;
}

// TODO: Заменить на китовую иконку в CD-170478, когда обновится ui-kit до 7 с новыми иконками.
/* istanbul ignore next */
export const IconPolice16: React.FC<React.PropsWithChildren<IIconPolice16Props>> = ({ color }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={cx(styles['icon'], color === 'icon-inverted-default' && styles['white'])}
    >
      <path
        fill="#707A95"
        fillRule="evenodd"
        d="M2 2.207 8 0l6 2.207v7.749a4 4 0 0 1-1.835 3.363L8 16l-4.165-2.68A4 4 0 0 1 2 9.955v-7.75Zm7.21 3.748L8 2.62 6.79 5.955l-3.545.121L6.04 8.258l-.98 3.409L8 9.68l2.939 1.986-.98-3.41 2.796-2.18-3.544-.122Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
