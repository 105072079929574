import * as React from 'react';

export const MortgageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 32 36" fill="none">
    <path
      d="M32 34C32 35.1046 31.1046 36 30 36H2C0.895431 36 0 35.1046 0 34V14.5776C0 13.9923 0.256425 13.4363 0.70166 13.0563L14.7017 1.10806C15.4496 0.469779 16.5505 0.469782 17.2983 1.10807L31.2983 13.0563C31.7436 13.4363 32 13.9923 32 14.5776V34Z"
      fill="url(#paint0_linear_3007_12764)"
    />
    <mask id="mask0_3007_12764" maskUnits="userSpaceOnUse" x="0" y="0" width="32" height="36">
      <path
        d="M32 34C32 35.1046 31.1046 36 30 36H2C0.895431 36 0 35.1046 0 34V14.5776C0 13.9923 0.256425 13.4363 0.70166 13.0563L14.7017 1.10806C15.4496 0.469779 16.5505 0.469782 17.2983 1.10807L31.2983 13.0563C31.7436 13.4363 32 13.9923 32 14.5776V34Z"
        fill="url(#paint1_linear_3007_12764)"
      />
    </mask>
    <g mask="url(#mask0_3007_12764)">
      <path d="M1.5 14.5L0 13.6552L16 0V1.9375L1.5 14.5Z" fill="url(#paint2_linear_3007_12764)" />
      <path d="M1.5 14.5011L0 13.6562V36.001H1.5V14.5011Z" fill="url(#paint3_linear_3007_12764)" />
    </g>
    <path
      d="M10.5146 20C11.9033 20 13.0291 18.8807 13.0291 17.5C13.0291 16.1193 11.9033 15 10.5146 15C9.12581 15 8 16.1193 8 17.5C8 18.8807 9.12581 20 10.5146 20Z"
      fill="white"
    />
    <path
      d="M21.0888 30.5098C22.4775 30.5098 23.6033 29.3905 23.6033 28.0098C23.6033 26.6291 22.4775 25.5098 21.0888 25.5098C19.7 25.5098 18.5742 26.6291 18.5742 28.0098C18.5742 29.3905 19.7 30.5098 21.0888 30.5098Z"
      fill="white"
    />
    <path d="M21.4749 14.9994L8 28.3945L10.1276 30.5095L23.6025 17.1145L21.4749 14.9994Z" fill="white" />
    <defs>
      <linearGradient
        id="paint0_linear_3007_12764"
        x1="5.24079"
        y1="4.64586"
        x2="23.4578"
        y2="25.8455"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28297" stopColor="#FC944F" />
        <stop offset="0.71915" stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3007_12764"
        x1="5.24079"
        y1="4.64586"
        x2="23.4578"
        y2="25.8455"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.28297" stopColor="#FC944F" />
        <stop offset="0.71915" stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3007_12764"
        x1="3.4375"
        y1="5.9375"
        x2="17.3125"
        y2="22.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0213583" stopColor="#FF7E00" stopOpacity="0.73" />
        <stop offset="0.234551" stopColor="#FFE3C8" />
        <stop offset="0.38981" stopColor="#FF7F01" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3007_12764"
        x1="0.750001"
        y1="14.1885"
        x2="0.750001"
        y2="36.001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.00573064" stopColor="#FFB064" />
        <stop offset="0.836676" stopColor="#FF7F01" />
      </linearGradient>
    </defs>
  </svg>
);

MortgageIcon.displayName = 'MortgageIcon';
