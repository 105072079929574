// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  ICreateBookingConsultingRequestRequest,
  TCreateBookingConsultingRequestModel,
  IMappers,
  TCreateBookingConsultingRequestResponse,
  ICreateBookingConsultingRequestResponseError,
} from './types';

export const defaultConfig: TCreateBookingConsultingRequestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'newbuilding-flat-view-order',
  pathApi: '/v1/create-booking-consulting-request/',
  requestType: 'json',
} as TCreateBookingConsultingRequestModel;

export function createCreateBookingConsultingRequestModel(
  parameters: ICreateBookingConsultingRequestRequest,
): TCreateBookingConsultingRequestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface ICreateBookingConsultingRequestOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
  parameters: ICreateBookingConsultingRequestRequest;
}

export async function fetchCreateBookingConsultingRequest<TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: ICreateBookingConsultingRequestOptions<TResponse400>): Promise<
  TCreateBookingConsultingRequestResponse | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(
    createCreateBookingConsultingRequestModel(parameters),
    config,
  );
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as ICreateBookingConsultingRequestResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TCreateBookingConsultingRequestResponse;
}
