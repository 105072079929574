import { setNewbuildingBookingFailure, setNewbuildingBookingLoading, setNewbuildingBookingSuccess } from './actions';
import { selectOfferId } from '../../selectors';
import { getNewbuildingId } from '../../selectors/newbuilding';
import { fetchSubmitContactsForBookedApartment } from '../../services/newbuildingBookedFromDevelopers';
import { IThunkActionCreator } from '../../store';

export const bookNewbuildingAction = ({ phone }: { phone: string }): IThunkActionCreator => {
  return async (dispatch, getState, { httpApi, logger }): Promise<void> => {
    try {
      const state = getState();
      const offerId = selectOfferId(state);
      const newbuildingId = getNewbuildingId(state);

      dispatch(setNewbuildingBookingLoading());

      await fetchSubmitContactsForBookedApartment(httpApi, {
        newbuildingId,
        offerId,
        phone,
      });

      dispatch(setNewbuildingBookingSuccess());
    } catch (error) {
      logger.error(error, {
        domain: 'NewbuildingCard.actions.bookNewbuildingAction',
        description: 'Ошибка при заказе звонка для забронированной квартиры',
        meta: 'newbuilding-reserved-offers/public/v1/submit-contacts/',
      });
      dispatch(setNewbuildingBookingFailure());
    }
  };
};
