import { IConfig } from '@cian/config/shared';
import { ConsultantWidgetDesktop } from '@cian/newbuilding-chat-component';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from 'usehooks-ts';

import { chatToggleOpen } from 'shared/actions/newbuildingChat';
import { useWebimProps } from 'shared/hooks/useWebimProps';

import { trackShowButton, trackClickChatOpen, trackClickChatClose } from './analytics';
import { isNeedToShow, updateShownTimestamp } from './utils/periodicActions';
import { prepareMessangers } from './utils/prepareMessangers';
import { CHAT_OPEN_DELAY, CHAT_FORCE_OPEN_KEY } from '../../constants/newbuildingChat';
import {
  chatAvailableSelector,
  isConsultantChatOpenedSelector,
  selectConsultantMessageButtonAvailable,
} from '../../selectors/newbuildingConsultant';

interface INewbuildingChatContainerProps {
  config: IConfig;
}

export function NewbuildingChatContainer({ config }: INewbuildingChatContainerProps) {
  const dispatch = useDispatch();
  const isOpen = useSelector(isConsultantChatOpenedSelector);
  const [needForceOpen, setNeedForceOpen] = React.useState(false);
  const [lastOpen, setLastOpen] = useLocalStorage<number>(CHAT_FORCE_OPEN_KEY, new Date().getTime());

  const onOpenedChange = React.useCallback(
    (isOpen: boolean) => {
      dispatch(chatToggleOpen(isOpen));

      if (isOpen) {
        setNeedForceOpen(false);
        trackClickChatOpen();
      } else {
        trackClickChatClose();
      }
    },
    [needForceOpen],
  );

  const needForceOpenRS = config.get<boolean>('newbuildingChat.needForceOpen');

  const chatAvailable = useSelector(chatAvailableSelector);
  const isConsultantMessageButtonAvailable = useSelector(selectConsultantMessageButtonAvailable);
  const isAvailable = chatAvailable && !isConsultantMessageButtonAvailable;

  const webimProps = useWebimProps();
  const messangersLinks = React.useMemo(() => prepareMessangers(config), []);

  const onReady = React.useCallback(() => {
    if (isAvailable && needForceOpen) {
      setTimeout(() => {
        dispatch(chatToggleOpen(isOpen));
        setNeedForceOpen(false);
        setLastOpen(updateShownTimestamp());
      }, CHAT_OPEN_DELAY);
    }
  }, [isAvailable, needForceOpen]);

  React.useEffect(() => {
    if (needForceOpenRS && isNeedToShow(lastOpen)) {
      setNeedForceOpen(true);
    }
  }, []);

  React.useEffect(() => {
    if (isAvailable) {
      trackShowButton();
    }
  }, [isAvailable]);

  if (!isAvailable) {
    return null;
  }

  return (
    <ConsultantWidgetDesktop
      webimProps={webimProps}
      chatOpened={isOpen}
      messengersLinks={messangersLinks}
      toggleOpen={onOpenedChange}
      onReady={onReady}
    />
  );
}
