import { LinkButton } from '@cian/ui-kit';
import { TButtonTheme } from '@cian/ui-kit/button/types/TButtonTheme';
import * as React from 'react';

export interface IMortgageLinkProps {
  dataTestId?: string;
  onClick?(event: React.MouseEvent): void;
  url: string;
  fullWidth?: boolean;
  theme?: TButtonTheme;
}

export const MortgageLink: React.FC<React.PropsWithChildren<IMortgageLinkProps>> = ({
  dataTestId = 'MortgageLink',
  theme = 'fill_secondary',
  onClick,
  url,
  fullWidth,
}) => {
  return (
    <LinkButton href={url} onClick={onClick} theme={theme} size="M" data-testid={dataTestId} fullWidth={fullWidth}>
      Получить ипотеку онлайн
    </LinkButton>
  );
};
