import cx from 'clsx';
import * as React from 'react';

import { PhoneLinkQRCode } from 'shared/components/PhoneLinkQRCode';

import * as styles from './Phones.css';

interface IPhone {
  link: string;
  number: string;
}

export interface IPhonesProps {
  phones: IPhone[];
  size?: 'xxs' | 'xs' | 's' | 'm';
  inRow?: boolean;
  withQrCode?: boolean;
  staticQrPreview?: boolean;
}

export function Phones({ phones, size = 'm', inRow, withQrCode, staticQrPreview }: IPhonesProps) {
  return (
    <div className={cx(styles['phones'], inRow && styles['row'])}>
      {phones.map((phone, index) => (
        <div
          className={cx(styles['phone'], styles[`phone-size-${size}`], withQrCode && styles['phone-qrcode'])}
          key={index}
        >
          <a href={phone.link} className={styles['phone-link']} data-testid="PhoneLink">
            {phone.number}
          </a>
          {withQrCode && <PhoneLinkQRCode phoneLink={phone.link} staticPreview={staticQrPreview} />}
        </div>
      ))}
    </div>
  );
}
