import { ca } from '@cian/analytics';

export function trackShowExpertOption(offerId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ChatBot',
    action: 'Show_sopr',
    label: 'Expert',
    page: {
      extra: {
        text: 'Главное из объявления',
      },
    },
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
}

export function trackClickExpertOptionButton(offerId: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'ChatBot',
    action: 'Click_sopr',
    label: 'Expert',
    page: {
      extra: {
        text: 'Главное из объявления',
      },
    },
    products: [
      {
        id: offerId,
        offerType: 'offer',
      },
    ],
  });
}
