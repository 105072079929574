import cx from 'clsx';
import * as React from 'react';

import { SpecialPromoPin } from 'shared/mf-infrastructure/components/Infrastructure/parts/MapPin/components/SpecialPromoPin';
import { INFRASTRUCTURE_ICON_MAP } from 'shared/mf-infrastructure/constants/infrastructure';
import { ICustomMainPin } from 'shared/mf-infrastructure/types/customMainPin';
import { TFutureRoad } from 'shared/mf-infrastructure/types/futureInfrastructure';
import { TInfrastructureKind, TRegularInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';
import { TTAInfrastructureType } from 'shared/mf-infrastructure/types/transportAccessibility';

import * as styles from './MapPin.css';

export interface IMapPinProps {
  type: TRegularInfrastructureType | TFutureRoad | TTAInfrastructureType;
  customMainPin?: ICustomMainPin | null;
  color?: 'blue' | 'green';
  kind?: TInfrastructureKind;
}

export const MapPin: React.FC<IMapPinProps> = ({ type, kind, customMainPin, color }) => {
  const iconKey = type === 'restAreas' && kind ? kind : type;

  const Icon = INFRASTRUCTURE_ICON_MAP.get(iconKey);

  if (customMainPin) {
    return <SpecialPromoPin backgroundColor={customMainPin.backgroundColor} imageUrl={customMainPin.logo} />;
  }

  if (type === 'main') {
    return <div className={styles['pin_main']} />;
  }

  if (!Icon) {
    return null;
  }

  const squareIcon = type === 'similar_newbuildings';

  return (
    <div
      className={cx(styles['pin'], color && styles[color], squareIcon && styles['square'])}
      data-testid={`MapPin-${type}`}
    >
      <Icon color="icon-inverted-default" />
    </div>
  );
};
