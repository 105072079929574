import { UIText1, UIText2, Image } from '@cian/ui-kit';
import { IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './NewbuildingMortgageWidgetPromoDiscount.css';
import newBuildingIcon from './assets/newbuilding-promo-ipoteka.png';
import { trackMortgageWidgetAnchorClick } from './tracking';

interface IUiData {
  redirectPath: string;
  linkText: string;
}

const dataMap: Record<string, IUiData> = {
  'vtb-hochu-stavky': {
    redirectPath:
      '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc',
    linkText: 'Ипотека со скидкой 3%',
  },
  'promo-domrf-9.99': {
    redirectPath:
      '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc_s30p&cian_campaign=save_30_percents',
    linkText: 'Сэкономьте на ипотеке до 30%',
  },
};

const defaultData: IUiData = {
  redirectPath:
    '/ipoteka-main/?cian_source=cian&cian_medium=trap&cian_term=card&cian_content=under_price_block_widget_desc',
  linkText: 'Ипотека',
};

interface INewbuildingMortgageWidgetPromoDiscountProps {
  mortgagePromoDesign: string | null;
}

export const NewbuildingMortgageWidgetPromoDiscount: React.FC<INewbuildingMortgageWidgetPromoDiscountProps> = ({
  mortgagePromoDesign,
}) => {
  // istanbul ignore next
  const data = mortgagePromoDesign !== null ? dataMap[mortgagePromoDesign] ?? defaultData : defaultData;

  const handleAnchorClick = () => {
    trackMortgageWidgetAnchorClick();
  };

  return (
    <div data-testid="mortgage-widget-promo" className={styles['container']}>
      <a
        href={data.redirectPath}
        target="_blank"
        rel="noreferrer"
        className={styles['anchor-next']}
        onClick={handleAnchorClick}
      >
        <div className={styles['anchor-icon']}>
          <Image src={newBuildingIcon} alt="logo" objectFit="contain" preloader={false} />
        </div>
        <div className={styles['text']}>
          <UIText1>Циан.Ипотека</UIText1>
          <UIText2 color="text-secondary-default">{data.linkText}</UIText2>
        </div>
        <div className={styles['arrow']}>
          <IconActionChevronRight16 color="icon-main-default" />
        </div>
      </a>
    </div>
  );
};
