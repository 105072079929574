import { IconBullet16, UIText1 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import { EInfrastructureType } from 'shared/mf-infrastructure/types/infrastructure';

import * as style from './style.css';

interface IInfrastructureTypeItemProps {
  checked: boolean;
  hasNewData: boolean;
  title: string;
  type: EInfrastructureType;
  icon: React.ReactNode;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InfrastructureTypeItem: React.FC<IInfrastructureTypeItemProps> = ({
  checked,
  hasNewData,
  icon,
  type,
  title,
  handleChange,
}) => {
  return (
    <label
      data-testid={`InfrastructureTypeItem-${type}${hasNewData ? '-hasNewData' : ''}`}
      aria-label={title}
      htmlFor={`infrastructure-map_${type}`}
      className={clsx(style['item'], checked && style['checked'])}
    >
      <input
        type="checkbox"
        name={'infrastructureMapTypes'}
        value={type}
        id={`infrastructure-map_${type}`}
        checked={checked}
        onChange={handleChange}
      />
      {icon}
      <UIText1>{title}</UIText1>
      {hasNewData && (
        <div className={style['future-type-icon']}>
          <IconBullet16 color="icon-positive-default" />
        </div>
      )}
    </label>
  );
};
