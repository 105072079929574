import { IconClose12 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface ICloseModalButtonProps {
  onClick: () => void;
}

export const CloseModalButton: React.FC<ICloseModalButtonProps> = ({ onClick }) => {
  return (
    <div className={style['close-button']} onClick={onClick}>
      <IconClose12 color="icon-inverted-default" />
    </div>
  );
};
