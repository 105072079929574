import { IState } from 'shared/store';

import { abUseExperimentsSelector, isAbUseExperimentsActive } from '../abUseExperiments';

// TODO: Удалить в CD-225475
export const NEWBUILDING_CONSULTANT_MESSAGE_BUTTON = 'newbuilding_new_entry_point_webim_desktop';

export const selectNewbuildingConsultantMessageButtonExperimentEnabled = (state: IState) => {
  const experiments = abUseExperimentsSelector(state);

  return isAbUseExperimentsActive(experiments, NEWBUILDING_CONSULTANT_MESSAGE_BUTTON, 'B');
};
