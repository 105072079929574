import { ActionLink, IconChevronDownSmall16, IconChevronUpSmall16 } from '@cian/ui-kit';
import React from 'react';

import * as styles from './ShowMoreButton.css';

interface IShowMoreButtonProps {
  onClick(): void;
  count: number;
  expanded: boolean;
}

export const ShowMoreButton: React.FC<IShowMoreButtonProps> = ({ onClick, count, expanded }) => {
  const text = expanded ? 'Свернуть' : `Показать ещё ${count}`;
  const IconComponent = expanded ? IconChevronUpSmall16 : IconChevronDownSmall16;

  return (
    <li className={styles['show-more-button']}>
      <ActionLink
        fontSize="S"
        spacingSize="auto"
        afterIcon={<IconComponent color="icon-main-default" />}
        onClick={onClick}
      >
        {text}
      </ActionLink>
    </li>
  );
};
