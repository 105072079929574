import { UIHeading5, IconRoute16 } from '@cian/ui-kit';
import clsx from 'clsx';
import * as React from 'react';

import * as style from './style.css';

interface IRouteButtonProps {
  fixed: boolean;
  onClick: () => void;
}

export const RouteButton: React.FC<IRouteButtonProps> = ({ fixed, onClick }) => {
  return (
    <div className={clsx(style['wrapper'], fixed ? style['fixed'] : style['normal'])}>
      <button className={style['button']} onClick={onClick}>
        <IconRoute16 color="icon-main-default" />
        <UIHeading5 color="text-primary-default">Построить маршрут</UIHeading5>
      </button>
    </div>
  );
};
