import { UIText1, UIText2 } from '@cian/ui-kit';
import { IconActionChevronRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './style.css';

interface IMainDiscountPromoProps {
  promoName: string;
  icon: React.ReactNode;
  onClick(): void;
}

export const MainDiscountPromo = ({ promoName, icon, onClick }: IMainDiscountPromoProps) => {
  return (
    <div className={style['container']} onClick={onClick} data-testid="MainDiscountPromo">
      <div className={style['icon']}>{icon}</div>
      <div className={style['text']}>
        <UIText1>{promoName}</UIText1>
        <UIText2 color="text-secondary-default">Акция от застройщика</UIText2>
      </div>
      <div className={style['arrow']}>
        <IconActionChevronRight16 color="icon-main-default" />
      </div>
    </div>
  );
};
