import { Image } from '@cian/ui-kit';
import * as React from 'react';

import { BankIcon } from '../../../components/NewbuildingPromos/parts/PromoItem/BankIcon';
import { getPromoIcon } from '../../../components/NewbuildingPromos/parts/PromoItem/PromoIcons/getPromoIcon';
import { EPromoType, IPromoInfo } from '../../../types/newbuildingPromos';

interface IPromoIconProps {
  promo: IPromoInfo;
}

export const PromoIcon: React.FC<IPromoIconProps> = ({ promo }) => {
  const { authorLogoUrl, isBlackFriday, promoType } = promo;

  if (isBlackFriday && authorLogoUrl) {
    return <Image src={authorLogoUrl} alt="logo" borderRadius="50%" objectFit="contain" />;
  }

  return promoType === EPromoType.Bank ? <BankIcon bank={promo.bank} /> : getPromoIcon(promoType);
};
