import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Phones } from 'shared/components/Phones';
import { NewbuildingDynamicPhone } from 'shared/containers/NewbuildingDynamicPhone';
import { getPhones } from 'shared/selectors';
import { getDynamicCalltrackingSiteBlockId } from 'shared/selectors/getDynamicCalltrackingSiteBlockId';
import { formatNumber, getPhoneLink } from 'shared/utils/phone';

interface IPhoneContainerProps {
  placeType: string;
}

export const PhoneContainer = ({ placeType }: IPhoneContainerProps) => {
  const isEnabledDynamicCallTracking = !!useSelector(getDynamicCalltrackingSiteBlockId);
  const phones = useSelector(getPhones);

  const formattedPhones = React.useMemo(
    () =>
      phones.map(phone => ({
        number: formatNumber(phone),
        link: getPhoneLink(phone),
      })),
    [phones],
  );

  return isEnabledDynamicCallTracking ? (
    <UIText2 fontWeight="bold">
      <NewbuildingDynamicPhone placeType={placeType} staticQrPreview={true} />
    </UIText2>
  ) : (
    <Phones size="xxs" phones={formattedPhones} inRow withQrCode staticQrPreview />
  );
};
