import { IMapGeoObject } from '@cian/frontend-newbuilding-map-component';
import { Map as NewbuildingMapComponent } from '@cian/frontend-newbuilding-map-component/es/components/Map/Map';
import { useCallback } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';

import { MapBalloonWithRoute } from 'shared/mf-infrastructure/components/Infrastructure/parts/MapBallonWithRoute';
import { INFRASTRUCTURE_ICON_MAP } from 'shared/mf-infrastructure/constants/infrastructure';
import { DISTANCE_CALCULATING_ENABLED_CONFIG_KEY } from 'shared/mf-infrastructure/constants/map';
import { useSimilarNewbuildingsBalloonRenderer } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useSimilarNewbuildingsBalloonRenderer';
import { useSimilarObjectsBalloonRenderer } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useSimilarObjectsBalloonRenderer';
import { useTransportAccessibilityRouting } from 'shared/mf-infrastructure/containers/InfrastructureContainer/hooks/useTransportAccessibilityRouting';
import { IInfrastructureProps } from 'shared/mf-infrastructure/containers/InfrastructureContainer/types';
import { IInfrastructureItem } from 'shared/mf-infrastructure/types/infrastructure';
import { EMapService, ICoordinates } from 'shared/mf-infrastructure/types/map';
import {
  ITAInfrastructureData,
  TTAInfrastructureItem,
  TTAInfrastructureType,
} from 'shared/mf-infrastructure/types/transportAccessibility';
import { useApplicationContext } from 'shared/mf-infrastructure/utils/applicationContext';
import {
  getDateText,
  getDescription,
  getIconBackgroundColor,
  getTimeWalk,
  isRouteForTypeAvailable,
} from 'shared/mf-infrastructure/utils/balloon';
import { findGeoObjectDatum } from 'shared/mf-infrastructure/utils/findGeoObjectDatum';
import { isTransportAccessibilityInfrastructureItem } from 'shared/mf-infrastructure/utils/isTransportAccessibilityInfrastructureItem';

import { MapBalloon } from '../../../components/Infrastructure';
import { trackTransportAccessibilityPinClick } from '../tracking';

interface IGeoObjectBalloonProps {
  infrastructureByYearMap: IInfrastructureProps['infrastructureByYearMap'];
  transportAccessibilityInfrastructure: Map<TTAInfrastructureType, ITAInfrastructureData>;
  startBuildingRoute: (item?: IInfrastructureItem | TTAInfrastructureItem) => void;
  mapService: EMapService | null;
  mapApi: NewbuildingMapComponent | null;
  mainPinCoordinates: ICoordinates;
}

export const useGeoObjectBalloon = ({
  infrastructureByYearMap,
  transportAccessibilityInfrastructure,
  startBuildingRoute,
  mapService,
  mapApi,
  mainPinCoordinates,
}: IGeoObjectBalloonProps) => {
  const { setTARouting, resetTARouting } = useTransportAccessibilityRouting(mapApi, mainPinCoordinates);
  const { config } = useApplicationContext();
  const { renderSimilarObjectsBalloon, onSimilarObjectsBalloonOpen } = useSimilarObjectsBalloonRenderer();
  const { renderSimilarNewbuildingsBalloon, onSimilarNewbuildingsBalloonOpen } =
    useSimilarNewbuildingsBalloonRenderer();

  const isDistanceCalculatingEnabled = config.get<boolean>(DISTANCE_CALCULATING_ENABLED_CONFIG_KEY) || false;

  const handleBalloonRouteButtonClick = useCallback(
    (item: IInfrastructureItem | TTAInfrastructureItem) => {
      if (isTransportAccessibilityInfrastructureItem(item)) {
        setTARouting(item as TTAInfrastructureItem);

        return;
      }

      startBuildingRoute(item);
    },
    [startBuildingRoute, setTARouting],
  );

  const handleBalloonHtmlFirstFetch = useCallback(
    async (geoObject: IMapGeoObject): Promise<string> => {
      const {
        options: { id },
        name: type,
      } = geoObject;

      if (type === 'similar_objects') {
        return renderSimilarObjectsBalloon(id);
      }

      if (type === 'similar_newbuildings') {
        return renderSimilarNewbuildingsBalloon(id);
      }

      const datum = findGeoObjectDatum(id, infrastructureByYearMap, transportAccessibilityInfrastructure, mapService);

      const item = datum?.infrastructureItem;

      if (!item) {
        return Promise.reject(new Error('Не верный тип пина!'));
      }

      const iconKey = item.type === 'restAreas' && 'kind' in item && item.kind ? item.kind : item.type;

      const IconComponent = INFRASTRUCTURE_ICON_MAP.get(iconKey);

      const balloonProps = {
        icon: IconComponent && <IconComponent color="icon-inverted-default" />,
        description: getDescription(item),
        routeButtonId: `route-button-${item.id}`,
        timeWalk: getTimeWalk(item),
        title: item.title || '',
        type,
        iconBackgroundColor: getIconBackgroundColor(item),
        dateText: getDateText(item),
      };

      const MapBallonComponent =
        isDistanceCalculatingEnabled && isRouteForTypeAvailable(item.type) ? MapBalloonWithRoute : MapBalloon;

      const markup = renderToStaticMarkup(<MapBallonComponent {...balloonProps} />);

      return Promise.resolve(markup);
    },
    [renderSimilarNewbuildingsBalloon, renderSimilarObjectsBalloon, isDistanceCalculatingEnabled, mapService],
  );

  const handleBalloonOpen = useCallback(
    (geoObject: IMapGeoObject) => {
      if (geoObject.name === 'similar_objects') {
        onSimilarObjectsBalloonOpen(geoObject.options.id);
      }

      if (geoObject.name === 'similar_newbuildings') {
        onSimilarNewbuildingsBalloonOpen(geoObject.options.id);
      }

      const datum = findGeoObjectDatum(
        geoObject.options.id,
        infrastructureByYearMap,
        transportAccessibilityInfrastructure,
        mapService,
      );
      const item = datum?.infrastructureItem as IInfrastructureItem;

      const routeButton = document.getElementById(`route-button-${item.id}`);

      if (routeButton) {
        routeButton.addEventListener('click', () => {
          handleBalloonRouteButtonClick(item);
        });
      }

      if (isTransportAccessibilityInfrastructureItem(item)) {
        trackTransportAccessibilityPinClick(item.type as TTAInfrastructureType);
        resetTARouting();
      }
    },
    [onSimilarNewbuildingsBalloonOpen, onSimilarObjectsBalloonOpen, handleBalloonRouteButtonClick, resetTARouting],
  );

  return {
    onBalloonHtmlFirstFetch: handleBalloonHtmlFirstFetch,
    onBalloonOpen: handleBalloonOpen,
    resetTARouting,
  };
};
