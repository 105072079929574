import { TColors } from '@cian/ui-kit-design-tokens/colors';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './IconCompetitor16.css';

interface IIconCompetitor16Props {
  color?: TColors;
}

// TODO: Заменить на китовую иконку в CD-170478, когда обновится ui-kit до 7 с новыми иконками.
/* istanbul ignore next */
export const IconCompetitor16: React.FC<React.PropsWithChildren<IIconCompetitor16Props>> = ({ color }) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      className={cx(styles['icon'], color === 'icon-inverted-default' && styles['white'])}
    >
      <path fill="#707A95" d="M4 0v9h3v7l7-10H9.5l3.833-6H4Z" />
    </svg>
  );
};
