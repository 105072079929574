import { IWebimUserDataAuthed } from 'shared/types/newbuildingChat';
import { ITypedReduxAction } from 'shared/types/redux';
import { actionGenerator } from 'shared/utils/redux/actionGenerator';

export enum ENewbuildingChatActionTypes {
  ToggleOpen = 'newbuildingChat/toggleOpen',
  SetUserData = 'newbuildingChat/setUserData',
}

export type TNewbuildingChatToggleOpen = ITypedReduxAction<ENewbuildingChatActionTypes.ToggleOpen, boolean>;
export type TNewbuildingChatSetUserData = ITypedReduxAction<
  ENewbuildingChatActionTypes.SetUserData,
  IWebimUserDataAuthed | null
>;

export const chatToggleOpen = actionGenerator<ENewbuildingChatActionTypes.ToggleOpen, boolean>(
  ENewbuildingChatActionTypes.ToggleOpen,
);

export const chatSetUserData = actionGenerator<ENewbuildingChatActionTypes.SetUserData, IWebimUserDataAuthed | null>(
  ENewbuildingChatActionTypes.SetUserData,
);

export type TNewbuildingChatActions = TNewbuildingChatToggleOpen | TNewbuildingChatSetUserData;
