import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { selectOffer } from 'shared/containers/AuthorAsideBrandContainer/selectors/selectors';
import { abUseExperimentsSelector, isAbUseExperimentsActive } from 'shared/selectors';
import { EXPERT_OPINION } from 'shared/services/abUseExperiments/experiments';
import { getDealType, isLongtermRent, isSale } from 'shared/utils/deal_type';
import { getOfferType, isCommercial, isOldbuilding, isSuburban } from 'shared/utils/offer_type';

export function useExpertOptionEnabled(group: string) {
  const experiments = useSelector(abUseExperimentsSelector);
  const offer = useSelector(selectOffer);
  const offerType = getOfferType(offer);
  const dealType = getDealType(offer);
  const { config } = useApplicationContext();

  const expertOpinionButtonEnabled = config.get<boolean>('frontend-offer-card.expertOpinionButton.Enabled') || false;
  const chatbotGPTEnabled = config.get<boolean>('frontend-chats.chatbotGPT.Enabled') || false;
  const activeGroup = isAbUseExperimentsActive(experiments, EXPERT_OPINION, group);

  return Boolean(
    activeGroup &&
      expertOpinionButtonEnabled &&
      chatbotGPTEnabled &&
      !isSuburban(offerType) &&
      ((!isCommercial(offerType) && isLongtermRent(dealType)) || (isSale(dealType) && isOldbuilding(offerType))),
  );
}
