import { UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './ResultScreen.css';

interface ISuccessScreenProps {
  title: string;
  subtitle: string;
  button: React.ReactNode;
  image: React.ReactNode;
}

export const ResultScreen = ({ image, title, subtitle, button }: ISuccessScreenProps) => {
  return (
    <div className={style['container']}>
      <div className={style['content']}>
        <div className={style['image-container']}>{image}</div>
        <div className={style['message']}>
          <UIHeading2 textAlign="center">{title}</UIHeading2>
          <UIText1 textAlign="center">{subtitle}</UIText1>
        </div>
      </div>

      <div className={style['controls']}>{button}</div>
    </div>
  );
};
