import { IHttpApi } from '@cian/http-api';
import { ILogger } from '@cian/logger';
import { HttpBadStatusCodeError } from '@cian/peperrors';

import { ResponseError } from 'shared/errors/responseError';
import {
  fetchCreateBookingConsultingRequest,
  ICreateBookingConsultingRequestRequest,
  ICreateBookingConsultingRequestResponse400,
} from 'shared/repositories/newbuilding-flat-view-order/v1/create-booking-consulting-request';

interface IFetchBookingRequestParams {
  parameters: ICreateBookingConsultingRequestRequest;
  logger: ILogger;
  httpApi: IHttpApi;
}

interface IResult {
  success: boolean;
}

export const fetchBookingRequest = async (params: IFetchBookingRequestParams): Promise<IResult> => {
  const { logger, parameters, httpApi } = params;

  try {
    const response = await fetchCreateBookingConsultingRequest<ICreateBookingConsultingRequestResponse400>({
      parameters,
      httpApi,
    });

    if (response.statusCode !== 200) {
      throw new HttpBadStatusCodeError(
        'Не удалось создать заявку на бронирование',
        response.statusCode,
        'fetchBookingRequest',
      );
    }

    return {
      success: true,
    };
  } catch (error) {
    logger.error(
      new ResponseError({
        domain: 'fetchBookingRequest',
        message: 'Ошибка отправки заявки на бронирование',
        details: { errors: JSON.stringify(error) },
      }),
    );

    return {
      success: false,
    };
  }
};
