import { createSelector } from '@reduxjs/toolkit';
import { pathOr } from 'ramda';

import { IPhoneSchema } from '../repositories/offer-card/entities/offer/PhoneSchema';
import { IState } from '../store';

export const getUserId = (state: IState): number | undefined => {
  return pathOr(undefined, ['offerData', 'user', 'cianUserId'], state);
};

export const getUserPhone = (state: IState): string | undefined => {
  return pathOr(undefined, ['offerData', 'user', 'phones', 0, 'phone'], state);
};

export const selectDefaultPhoneNumber = createSelector(getUserPhone, (phone: string | undefined) => {
  return phone && phone.substring(1);
});

export const getAgentPhone = (state: IState): IPhoneSchema | null => {
  const phone = pathOr(null, ['offerData', 'offer', 'phones', 0], state);

  return phone;
};

export const getUserName = (state: IState): string => {
  const firstName = state.offerData.user?.firstName || '';
  const lastName = state.offerData.user?.lastName || '';

  return `${firstName} ${lastName}`.trim();
};
