import * as React from 'react';

export const ActionIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39" fill="none">
    <path
      d="M19.105 2.87305C10.1436 2.87305 2.87891 10.1377 2.87891 19.0991C2.87891 23.4756 4.61153 27.4474 7.42826 30.3659L6.68952 31.0404C3.71047 27.9439 1.87891 23.7354 1.87891 19.0991C1.87891 9.58543 9.59129 1.87305 19.105 1.87305C24.3957 1.87305 29.1293 4.25816 32.2892 8.0118L31.542 8.67702C28.5655 5.12885 24.0987 2.87305 19.105 2.87305Z"
      fill="url(#paint0_linear_3007_12829)"
    />
    <path
      d="M19.103 35.3252C28.0644 35.3252 35.3291 28.0605 35.3291 19.0991C35.3291 15.1313 33.9049 11.4962 31.5399 8.67693L32.2872 8.01172C34.8094 11.0079 36.3291 14.876 36.3291 19.0991C36.3291 28.6128 28.6167 36.3252 19.103 36.3252C14.2255 36.3252 9.82153 34.298 6.6875 31.0404L7.42624 30.3658C10.3772 33.4235 14.518 35.3252 19.103 35.3252Z"
      fill="url(#paint1_linear_3007_12829)"
    />
    <path
      d="M19.1039 37.2C29.1003 37.2 37.2039 29.0964 37.2039 19.1C37.2039 9.10365 29.1003 1 19.1039 1C9.10755 1 1.00391 9.10365 1.00391 19.1C1.00391 29.0964 9.10755 37.2 19.1039 37.2Z"
      stroke="#3AC400"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
    <path
      d="M19.0732 0.978111C9.07954 0.978111 0.978111 9.07954 0.978111 19.0732C0.978111 24.5672 3.42657 29.4893 7.29183 32.8079L6.56062 33.4688C2.54099 29.9719 0 24.8193 0 19.0732C0 8.53935 8.53935 0 19.0732 0C25.2387 0 30.721 2.9255 34.2076 7.4641L33.3704 7.98035C30.0599 3.71963 24.8867 0.978111 19.0732 0.978111Z"
      fill="url(#paint2_linear_3007_12829)"
    />
    <path d="M10.2031 17.1992L4.18359 22.6094L5.05859 22.3594L10.707 17.2695L10.2031 17.1992Z" fill="#66E530" />
    <path d="M10.207 17.2031L10.6992 17.2656L7.89453 10.2188L7.19141 9.70312L10.207 17.2031Z" fill="#45CA0D" />
    <path d="M15.0977 11L7.19141 9.70312L7.91016 10.2188L15.3477 11.4297L15.0977 11Z" fill="#E1FFD4" />
    <path d="M19.1055 3.89062L15.1055 11.0078L15.3555 11.4219L19.1055 4.72656V3.89062Z" fill="#97F56F" />
    <path d="M19.1133 4.73438V3.89062L23.1211 10.9844L22.8789 11.4141L19.1133 4.73438Z" fill="#3EC605" />
    <path d="M30.332 10.1875L22.8867 11.4141L23.1133 10.9844L31.0117 9.69531L30.332 10.1875Z" fill="#3EC605" />
    <path d="M30.332 10.1875L31.0117 9.70312L28.0273 17.1797L27.5117 17.2656L30.332 10.1875Z" fill="#4ACD13" />
    <path d="M33.168 22.3672L27.5195 17.2656L28.0195 17.1797L34.0039 22.6094L33.168 22.3672Z" fill="#60D92D" />
    <path
      d="M26.3086 24.8984C28.8607 24.1328 33.9727 22.6016 34.0039 22.6016L33.1758 22.3672L25.9023 24.5391L26.3086 24.8984Z"
      fill="#6EE13E"
    />
    <path d="M25.3398 32.1797L25.9023 24.5391L26.3164 24.8984L25.707 32.9922L25.3398 32.1797Z" fill="#7CE84F" />
    <path d="M19.1133 28.3984L25.707 32.9922L25.332 32.1797L19.1133 27.8359V28.3984Z" fill="#5FD82C" />
    <path d="M12.8867 32.1719L19.1133 27.8359V28.3906L12.5117 32.9922L12.8867 32.1719Z" fill="#3CC502" />
    <path d="M11.9102 24.9062L12.5117 32.9844L12.8711 32.1797L12.3086 24.5391L11.9102 24.9062Z" fill="#3BC501" />
    <path d="M5.05859 22.3672L12.3086 24.5391L11.9102 24.9062L4.19922 22.6016L5.05859 22.3672Z" fill="#3BC501" />
    <path
      d="M19.1056 4.7168L22.8808 11.4178L30.3369 10.1908L27.5055 17.2694L33.1683 22.3659L25.901 24.5366L25.3347 32.1814L19.1056 27.8399L12.8765 32.1814L12.3102 24.5366L5.04297 22.3659L10.7058 17.2694L7.87437 10.1908L15.3304 11.4178L19.1056 4.7168Z"
      fill="#3AC500"
    />
    <path
      d="M19.1056 4.7168L22.8808 11.4178L30.3369 10.1908L27.5055 17.2694L33.1683 22.3659L25.901 24.5366L25.3347 32.1814L19.1056 27.8399L12.8765 32.1814L12.3102 24.5366L5.04297 22.3659L10.7058 17.2694L7.87437 10.1908L15.3304 11.4178L19.1056 4.7168Z"
      fill="url(#paint3_linear_3007_12829)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3007_12829"
        x1="32.2892"
        y1="7.99915"
        x2="7.10498"
        y2="31.0404"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3AC400" />
        <stop offset="0.5" stopColor="#61E42A" />
        <stop offset="1" stopColor="#3AC400" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3007_12829"
        x1="30.728"
        y1="7.24902"
        x2="7.16553"
        y2="30.999"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3AC400" />
        <stop offset="0.584084" stopColor="#7DF14C" />
        <stop offset="1" stopColor="#3AC400" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3007_12829"
        x1="34.2076"
        y1="7.33583"
        x2="5.44074"
        y2="31.1773"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#3AC400" />
        <stop offset="0.484375" stopColor="white" />
        <stop offset="1" stopColor="#3AC400" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3007_12829"
        x1="7.10571"
        y1="8.99915"
        x2="31.6057"
        y2="28.4991"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0341662" stopColor="#77F143" />
        <stop offset="0.494152" stopColor="#50D717" />
        <stop offset="0.821902" stopColor="#3AC400" />
      </linearGradient>
    </defs>
  </svg>
);

ActionIcon.displayName = 'ActionIcon';
