import * as React from 'react';

export const MilitaryMortgageIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="43" viewBox="0 0 24 43" fill="none">
    <circle cx="12.0324" cy="30.755" r="9.69645" fill="#FFC42C" />
    <path
      d="M21.7288 30.755C21.7288 36.1102 17.3876 40.4515 12.0324 40.4515C6.67719 40.4515 2.33594 36.1102 2.33594 30.755C2.33594 25.3998 6.67719 21.0586 12.0324 21.0586C17.3876 21.0586 21.7288 25.3998 21.7288 30.755ZM2.89237 30.755C2.89237 35.8029 6.9845 39.8951 12.0324 39.8951C17.0803 39.8951 21.1724 35.8029 21.1724 30.755C21.1724 25.7072 17.0803 21.615 12.0324 21.615C6.9845 21.615 2.89237 25.7072 2.89237 30.755Z"
      fill="url(#paint0_linear_3007_12897)"
    />
    <path
      d="M17.7019 25.087C18.4463 25.8315 19.0368 26.7152 19.4397 27.6879C19.8426 28.6606 20.05 29.7031 20.05 30.7559C20.05 31.8087 19.8426 32.8512 19.4397 33.8238C19.0368 34.7965 18.4463 35.6803 17.7019 36.4247C16.9574 37.1691 16.0736 37.7597 15.101 38.1626C14.1283 38.5655 13.0858 38.7728 12.033 38.7728C10.9802 38.7728 9.93771 38.5655 8.96505 38.1626C7.99238 37.7597 7.1086 37.1691 6.36416 36.4247L6.93104 35.8578C7.60104 36.5278 8.39645 37.0593 9.27184 37.4219C10.1472 37.7845 11.0855 37.9711 12.033 37.9711C12.9805 37.9711 13.9188 37.7845 14.7942 37.4219C15.6696 37.0593 16.465 36.5278 17.135 35.8578C17.805 35.1878 18.3364 34.3924 18.699 33.517C19.0616 32.6416 19.2483 31.7034 19.2483 30.7559C19.2483 29.8083 19.0616 28.8701 18.699 27.9947C18.3364 27.1193 17.805 26.3239 17.135 25.6539L17.7019 25.087Z"
      fill="url(#paint1_linear_3007_12897)"
    />
    <path
      d="M6.36456 36.4247C5.62011 35.6803 5.02959 34.7965 4.6267 33.8238C4.22381 32.8512 4.01644 31.8087 4.01644 30.7559C4.01644 29.7031 4.22381 28.6606 4.6267 27.6879C5.02959 26.7152 5.62011 25.8315 6.36456 25.087C7.109 24.3426 7.99278 23.752 8.96544 23.3492C9.93811 22.9463 10.9806 22.7389 12.0334 22.7389C13.0862 22.7389 14.1287 22.9463 15.1014 23.3492C16.074 23.752 16.9578 24.3426 17.7022 25.087L17.1354 25.6539C16.4654 24.9839 15.67 24.4524 14.7946 24.0898C13.9192 23.7272 12.9809 23.5406 12.0334 23.5406C11.0859 23.5406 10.1476 23.7272 9.27224 24.0898C8.39684 24.4524 7.60144 24.9839 6.93144 25.6539C6.26144 26.3239 5.72997 27.1193 5.36737 27.9947C5.00477 28.8701 4.81814 29.8083 4.81814 30.7559C4.81814 31.7034 5.00477 32.6416 5.36737 33.517C5.72997 34.3924 6.26144 35.1878 6.93144 35.8578L6.36456 36.4247Z"
      fill="url(#paint2_linear_3007_12897)"
    />
    <path d="M0.71875 0.0507812H23.3438V15.7918L12.0313 20.2517L0.71875 15.7918V0.0507812Z" fill="#3AC500" />
    <path d="M5.57031 0.0507812H18.4989V17.7014L12.0346 20.2517L5.57031 17.7014V0.0507812Z" fill="#FFCE2C" />
    <path d="M9.60938 0.0507812H14.4576V19.2796L12.0335 20.2517L9.60938 19.2796V0.0507812Z" fill="#3AC500" />
    <path
      d="M12.0332 24.0391L13.5412 28.6803H18.4213L14.4732 31.5487L15.9812 36.1899L12.0332 33.3215L8.08517 36.1899L9.59319 31.5487L5.64515 28.6803H10.5252L12.0332 24.0391Z"
      fill="white"
    />
    <rect x="0.71875" width="22.6502" height="8.33289" fill="url(#paint3_linear_3007_12897)" />
    <defs>
      <linearGradient
        id="paint0_linear_3007_12897"
        x1="12.0324"
        y1="31.1591"
        x2="5.16407"
        y2="24.2907"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFCE2C" stopOpacity="0" />
        <stop offset="1" stopColor="#FFEAA5" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3007_12897"
        x1="12.033"
        y1="30.7558"
        x2="17.7019"
        y2="36.4247"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC42C" />
        <stop offset="1" stopColor="#FFE693" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3007_12897"
        x1="12.0334"
        y1="30.7559"
        x2="6.36456"
        y2="25.087"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFC42C" stopOpacity="0" />
        <stop offset="1" stopColor="#E7AC16" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3007_12897"
        x1="12.0439"
        y1="0"
        x2="12.0439"
        y2="8.33289"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.2" />
        <stop offset="0.224242" stopColor="white" stopOpacity="0.62" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

MilitaryMortgageIcon.displayName = 'MilitaryMortgageIcon';
