import { getIsFromBuilder } from 'shared/selectors/newObject';
import { IState } from 'shared/store';

import { developerIdSelector } from '../developer';
import { regionIdSelector } from '../offerData/geo/regionIdSelector';

export const selectIsBookingFormEnabled = (state: IState) => {
  const isFromBuilder = getIsFromBuilder(state);
  const regionId = regionIdSelector(state);
  const developerId = developerIdSelector(state);

  const isEnabled = state.rs.newbuildingBookingForm.enabled;

  if (!isEnabled || !isFromBuilder || !developerId || !regionId) {
    return false;
  }

  const { regions, builders } = state.rs.newbuildingBookingForm;

  return regions.includes(regionId) && builders.includes(developerId);
};
