import { useBooleanState } from '@cian/react-utils';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useApplicationContext } from 'shared/containers/ApplicationContext';
import { undergroundsSelector } from 'shared/selectors/offerData/geo/undergroundsSelector';

export const useUndergrounds = () => {
  const { state: expanded, setTrue: setExpanded, setFalse: setCollapsed } = useBooleanState(false);
  const { config } = useApplicationContext();
  const defaultVisibleCount = config.get<number>('defaultVisibleUndergrounds') || 3;
  const allUndergrounds = useSelector(undergroundsSelector);
  const allUndergroundsCount = allUndergrounds?.length || 0;

  const visibleUndergrounds = useMemo(() => {
    if (!allUndergrounds) {
      return [];
    }

    if (expanded) {
      return allUndergrounds;
    }

    return allUndergrounds.slice(0, defaultVisibleCount);
  }, [expanded, allUndergrounds, defaultVisibleCount]);

  const hiddenUndergroundsCount = allUndergroundsCount - visibleUndergrounds.length;
  const expandable = allUndergroundsCount > defaultVisibleCount;

  const toggleExpanded = useCallback(() => {
    if (expanded) {
      setCollapsed();
    } else {
      setExpanded();
    }
  }, [expanded, setCollapsed, setExpanded]);

  return {
    expandable,
    expanded,
    undergrounds: visibleUndergrounds,
    hiddenUndergroundsCount,
    toggleExpanded,
  };
};
