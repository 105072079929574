import { UIHeading4, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './AuthorLabel.css';

export interface IAuthorLabelProps {
  label?: string | null;
  logoIcon: React.ReactNode;
  logoUrl?: string | null;
  name: string;
}

export const AuthorLabel: React.FC<React.PropsWithChildren<IAuthorLabelProps>> = ({
  label,
  logoIcon,
  logoUrl,
  name,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['logo']}>
        {logoUrl ? (
          <img className={styles['logo-image']} src={logoUrl} alt={name} />
        ) : (
          <div className={styles['logo-icon']}>{logoIcon}</div>
        )}
      </div>
      <div className={styles['content']}>
        {label && <UIText3 color="gray60_100">{label}</UIText3>}
        <UIHeading4>
          <span className={styles['nowrap-text']}>{name}</span>
        </UIHeading4>
      </div>
    </div>
  );
};
