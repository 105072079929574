import { Button } from '@cian/ui-kit';
import { TButtonSize } from '@cian/ui-kit/button/types';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { chatToggleOpen } from 'shared/actions/newbuildingChat';
import { SendMessageButton } from 'shared/components/SendMessageButton/SendMessageButton';
import { getOfferId } from 'shared/selectors/newObject';
import { isConsultantChatOpenedSelector } from 'shared/selectors/newbuildingConsultant';

import { trackClickChatOpen } from './tracking';

interface IConsultantChatButtonContainerProps {
  buttonSize?: TButtonSize;
}

export const ConsultantChatButtonContainer: React.FC<IConsultantChatButtonContainerProps> = ({ buttonSize = 'M' }) => {
  const dispatch = useDispatch();

  const isOpen = useSelector(isConsultantChatOpenedSelector);
  const offerId = useSelector(getOfferId) as number;

  const handleOpen = React.useCallback(() => {
    if (!isOpen) {
      dispatch(chatToggleOpen(true));
      trackClickChatOpen(offerId);
    }
  }, [dispatch, isOpen, offerId]);

  return (
    <SendMessageButton builderButton>
      <Button theme="fill_secondary" size={buttonSize} onClick={handleOpen} fullWidth>
        Написать
      </Button>
    </SendMessageButton>
  );
};
