import { TChatOnboardingType } from './types';

const TEXT_MAP: Record<TChatOnboardingType, string> = {
  builder: 'Теперь можно написать застройщику в чате',
  consultant: 'Консультант подберёт квартиру по\u00a0вашим параметрам и\u00a0ответит на\u00a0вопросы',
};

const ONBOARDING_COOKIE_NAME_MAP: Record<TChatOnboardingType, string> = {
  builder: 'frontend-offer-card.builder_chat_onboarding_shown',
  consultant: 'frontend-offer-card.consultant_chat_onboarding_shown',
};

export { TEXT_MAP, ONBOARDING_COOKIE_NAME_MAP };
