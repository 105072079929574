import { prepareUserData } from '@cian/newbuilding-chat-component';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getWebimUserDataSelector } from 'shared/selectors/getWebimUserData';
import { accountNameSelector, chatHostSelector } from 'shared/selectors/newbuildingConsultant';

export const useWebimProps = () => {
  const user = useSelector(getWebimUserDataSelector);
  const accountName = useSelector(accountNameSelector);
  const host = useSelector(chatHostSelector);

  return useMemo(
    () => ({
      accountName: accountName as string,
      userData: prepareUserData(user),
      host: host || undefined,
    }),
    [accountName, host, user],
  );
};
