import * as React from 'react';
import { useSelector } from 'react-redux';

import { isNewbuildingFromDeveloperSelector } from 'shared/selectors';
import { selectConsultantMessageButtonAvailable } from 'shared/selectors/newbuildingConsultant';
import { selectIsChatsEnabled } from 'shared/selectors/offerChat';

import { SendMessageButtonContainer } from './SendMessageButton';
import { ChatOnboardingContainer } from '../ChatOnboardingContainer';
import { ConsultantChatButtonContainer } from '../ConsultantChatButtonContainer';

interface IMessageButtonContainerProps {
  buttonTitle: string;
}

export const MessageButtonContainer: React.FC<IMessageButtonContainerProps> = ({ buttonTitle }) => {
  const isConsultantMessageButtonAvailable = useSelector(selectConsultantMessageButtonAvailable);
  const isChatsEnabled = useSelector(selectIsChatsEnabled);
  const isNewbuildingFromDeveloper = useSelector(isNewbuildingFromDeveloperSelector);

  // TODO: Удалить в CD-225475, если эксперимент неудачен.
  if (!isChatsEnabled && isConsultantMessageButtonAvailable) {
    return (
      <ChatOnboardingContainer type="consultant">
        <ConsultantChatButtonContainer />
      </ChatOnboardingContainer>
    );
  }

  // TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
  if (isChatsEnabled && isNewbuildingFromDeveloper) {
    return (
      <ChatOnboardingContainer type="builder">
        <SendMessageButtonContainer title={buttonTitle} />
      </ChatOnboardingContainer>
    );
  }

  return <SendMessageButtonContainer title={buttonTitle} />;
};
