import { ENewbuildingChatActionTypes, TNewbuildingChatActions } from 'shared/actions/newbuildingChat';

import { INewbuildingChat } from '../../types/newbuildingChat';

const initialState = {
  userData: null,
  isOpen: false,
};

export function newbuildingChatReducer(
  state: INewbuildingChat = initialState,
  action: TNewbuildingChatActions,
): INewbuildingChat {
  switch (action.type) {
    case ENewbuildingChatActionTypes.SetUserData:
      return {
        ...state,
        userData: action.payload,
      };
    case ENewbuildingChatActionTypes.ToggleOpen:
      return {
        ...state,
        isOpen: action.payload,
      };
    default:
      return state;
  }
}
