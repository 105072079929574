import { IconProductSpecialLoanRate24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './BankItem.css';
import { AuthorLabel } from '../AuthorLabel';
import { MortgageLink } from '../MortgageLink';

interface IBankItemProps {
  isPartner?: boolean | null;
  label?: string | null;
  logoUrl?: string | null;
  mortgagePartnerLink?: string | null;
  name: string;
  onMortgageClick?(): void;
}

export const BankItem: React.FC<React.PropsWithChildren<IBankItemProps>> = ({
  isPartner,
  label,
  logoUrl,
  mortgagePartnerLink,
  name,
  onMortgageClick,
}) => {
  return (
    <div className={styles['container']}>
      <div className={styles['info']}>
        <AuthorLabel name={name} label={label} logoIcon={<IconProductSpecialLoanRate24 />} logoUrl={logoUrl} />
      </div>

      {isPartner && mortgagePartnerLink && (
        <div className={styles['link']}>
          <MortgageLink
            url={mortgagePartnerLink}
            onClick={onMortgageClick}
            dataTestId="BankItemPartnerLink"
            fullWidth
          />
        </div>
      )}
    </div>
  );
};
