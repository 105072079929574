import { ICookieOptions, setCookie } from '@cian/utils/lib/browser/cookie';
import { parseCookies } from '@cian/utils/lib/shared/cookie';

import { ONBOARDING_COOKIE_NAME_MAP } from '../constants';
import { TChatOnboardingType } from '../types';

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
export const getChatOnboardingCookie = (onboardingType: TChatOnboardingType) => {
  if (typeof window !== 'undefined') {
    const cookie = parseCookies(document.cookie)[ONBOARDING_COOKIE_NAME_MAP[onboardingType]];

    return cookie === '1';
  }

  return false;
};

export const setChatOnboardingCookie = (onboardingType: TChatOnboardingType) => {
  const cookieConfig: ICookieOptions = { expires: 365 * 86400, secure: false };
  const isLocalhost = typeof window !== 'undefined' && window.location.hostname.indexOf('localhost') > -1;

  if (isLocalhost) {
    cookieConfig.domain = 'localhost';
  }

  setCookie(ONBOARDING_COOKIE_NAME_MAP[onboardingType], '1', cookieConfig);
};
