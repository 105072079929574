/* eslint-disable max-lines */
import * as React from 'react';

/* istanbul ignore next */
export const MaternalCapitalIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="36" viewBox="0 0 32 36" fill="none">
    <path
      d="M11.8432 24.071H16.2268C22.7636 24.071 28.07 18.7646 28.07 12.2278H14.0735L13.9966 0H11.8432C5.30639 0 0 5.30639 0 11.8432V12.2278C0 18.7646 5.30639 24.071 11.8432 24.071Z"
      fill="url(#paint0_linear_3007_12795)"
    />
    <path
      d="M28.07 12.1504H0C0 18.7337 5.33684 24.0705 11.9202 24.0705H16.1499C22.7332 24.0705 28.07 18.7337 28.07 12.1504Z"
      fill="url(#paint1_linear_3007_12795)"
    />
    <path
      d="M11.8432 24.071H16.2268C22.7636 24.071 28.07 18.7646 28.07 12.2278H14.0735L13.9966 0H11.8432C5.30639 0 0 5.30639 0 11.8432V12.2278C0 18.7646 5.30639 24.071 11.8432 24.071Z"
      fill="url(#paint2_linear_3007_12795)"
    />
    <path
      d="M29.2248 9.9204C30.3716 9.9204 31.3013 8.99076 31.3013 7.84399C31.3013 6.69722 30.3716 5.76758 29.2248 5.76758C28.0781 5.76758 27.1484 6.69722 27.1484 7.84399C27.1484 8.99076 28.0781 9.9204 29.2248 9.9204Z"
      fill="#FF7E00"
    />
    <path
      d="M27.3791 7.84399C27.3791 6.84424 28.2251 5.99829 29.2248 5.99829C29.7632 5.99829 30.2246 6.229 30.5322 6.53662L30.686 6.38281C30.3015 5.99829 29.7632 5.76758 29.2248 5.76758C28.0713 5.76758 27.1484 6.69043 27.1484 7.84399C27.1484 8.45923 27.3791 8.99755 27.7637 9.30517L27.9175 9.15136C27.6099 8.84375 27.3791 8.38232 27.3791 7.84399Z"
      fill="url(#paint3_linear_3007_12795)"
    />
    <path
      d="M6.99755 25.1484C4.229 25.1484 1.92188 27.3787 1.92188 30.2241C1.92188 32.9927 4.1521 35.2998 6.99755 35.2998C9.84301 35.2998 12.0732 33.0696 12.0732 30.2241C11.9963 27.3787 9.7661 25.1484 6.99755 25.1484ZM6.99755 32.2236C5.84399 32.2236 4.99804 31.3008 4.99804 30.2241C4.99804 29.0706 5.92089 28.2246 6.99755 28.2246C8.07421 28.2246 8.99706 29.1475 8.99706 30.2241C8.99706 31.3008 8.07421 32.2236 6.99755 32.2236Z"
      fill="#FF7E00"
    />
    <path
      d="M21.9194 25.1484C19.1509 25.1484 16.8438 27.3787 16.8438 30.2241C16.8438 32.9927 19.074 35.2998 21.9194 35.2998C24.7649 35.2998 26.9951 33.0696 26.9951 30.2241C26.9182 27.3787 24.688 25.1484 21.9194 25.1484ZM21.9194 32.2236C20.7659 32.2236 19.9199 31.3008 19.9199 30.2241C19.9199 29.0706 20.8428 28.2246 21.9194 28.2246C22.9961 28.2246 23.9189 29.1475 23.9189 30.2241C23.9189 31.3008 22.9961 32.2236 21.9194 32.2236Z"
      fill="#FF7E00"
    />
    <path
      d="M2.4602 30.2241C2.4602 27.7632 4.45971 25.7637 6.92065 25.7637C8.15111 25.7637 9.30468 26.302 10.1506 27.1479L10.612 26.6865C9.6892 25.7637 8.38183 25.1484 6.99755 25.1484C4.229 25.1484 1.92188 27.3787 1.92188 30.2241C1.92188 31.6853 2.53711 32.9158 3.45996 33.8386L3.92138 33.3772C2.99853 32.6081 2.4602 31.4546 2.4602 30.2241Z"
      fill="url(#paint4_linear_3007_12795)"
    />
    <path
      d="M8.95434 30.231C8.95434 31.3845 8.03149 32.2305 6.95483 32.2305C6.4165 32.2305 5.87817 31.9998 5.49365 31.6152L5.33984 31.8459C5.72436 32.3074 6.3396 32.5381 6.95483 32.5381C8.1853 32.5381 9.26196 31.5383 9.26196 30.231C9.26196 29.6157 9.03124 29.0005 8.56982 28.5391L8.41601 28.7698C8.72363 29.1543 8.95434 29.6926 8.95434 30.231Z"
      fill="url(#paint5_linear_3007_12795)"
    />
    <path
      d="M17.3821 30.2241C17.3821 27.7632 19.3816 25.7637 21.8425 25.7637C23.073 25.7637 24.2266 26.302 25.0725 27.1479L25.5339 26.6865C24.6111 25.7637 23.3037 25.1484 21.9194 25.1484C19.1509 25.1484 16.8438 27.3787 16.8438 30.2241C16.8438 31.6853 17.459 32.9158 18.3818 33.8386L18.8433 33.3772C17.9204 32.6081 17.3821 31.4546 17.3821 30.2241Z"
      fill="url(#paint6_linear_3007_12795)"
    />
    <path
      d="M23.8801 30.2251C23.8801 31.3787 22.9573 32.2246 21.8806 32.2246C21.3423 32.2246 20.804 31.9939 20.4194 31.6094L20.2656 31.8401C20.6501 32.3015 21.2654 32.5322 21.8806 32.5322C23.1111 32.5322 24.1877 31.5325 24.1877 30.2251C24.1877 29.6099 23.957 28.9946 23.4956 28.5332L23.3418 28.7639C23.5725 29.1484 23.8801 29.6868 23.8801 30.2251Z"
      fill="url(#paint7_linear_3007_12795)"
    />
    <path
      d="M11.5347 30.2239C11.5347 32.6848 9.53515 34.6843 7.07421 34.6843C5.84375 34.6843 4.69018 34.146 3.84424 33.3L3.38281 33.7615C4.30566 34.6843 5.61303 35.2995 6.99731 35.2995C9.76586 35.2995 12.073 33.0693 12.073 30.2239C12.073 28.7627 11.4578 27.5322 10.5349 26.6094L10.0735 27.0708C10.9963 27.8398 11.5347 28.9934 11.5347 30.2239Z"
      fill="url(#paint8_linear_3007_12795)"
    />
    <path
      d="M26.4565 30.2239C26.4565 32.6848 24.457 34.6843 21.9961 34.6843C20.7656 34.6843 19.6121 34.146 18.7661 33.3L18.3047 33.7615C19.2275 34.6843 20.5349 35.2995 21.9192 35.2995C24.6877 35.2995 26.9949 33.0693 26.9949 30.2239C26.9949 28.7627 26.3796 27.5322 25.4568 26.6094L24.9954 27.0708C25.9951 27.8398 26.4565 28.9934 26.4565 30.2239Z"
      fill="url(#paint9_linear_3007_12795)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3007_12795"
        x1="1.13265"
        y1="1.73734"
        x2="22.3091"
        y2="22.9137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.000751314" stopColor="#FF8003" stopOpacity="0.6016" />
        <stop offset="0.0105656" stopColor="#FF9227" stopOpacity="0.6228" />
        <stop offset="0.0212945" stopColor="#FFA247" stopOpacity="0.646" />
        <stop offset="0.0334424" stopColor="#FFB062" stopOpacity="0.6722" />
        <stop offset="0.0471409" stopColor="#FFBB79" stopOpacity="0.7017" />
        <stop offset="0.0631027" stopColor="#FFC489" stopOpacity="0.7362" />
        <stop offset="0.0827946" stopColor="#FFCA95" stopOpacity="0.7787" />
        <stop offset="0.1103" stopColor="#FFCD9C" stopOpacity="0.838" />
        <stop offset="0.1854" stopColor="#FFCE9E" />
        <stop offset="0.4334" stopColor="#FFA853" />
        <stop offset="0.6518" stopColor="#FF8A17" />
        <stop offset="0.7556" stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3007_12795"
        x1="14.0651"
        y1="23.2335"
        x2="14.0651"
        y2="12.6146"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity="0" />
        <stop offset="1" stopColor="#FF7E00" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3007_12795"
        x1="1.13265"
        y1="1.73734"
        x2="22.3091"
        y2="22.9137"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.000751314" stopColor="#FF8003" stopOpacity="0.6016" />
        <stop offset="0.0105656" stopColor="#FF9227" stopOpacity="0.6228" />
        <stop offset="0.0212945" stopColor="#FFA247" stopOpacity="0.646" />
        <stop offset="0.0334424" stopColor="#FFB062" stopOpacity="0.6722" />
        <stop offset="0.0471409" stopColor="#FFBB79" stopOpacity="0.7017" />
        <stop offset="0.0631027" stopColor="#FFC489" stopOpacity="0.7362" />
        <stop offset="0.0827946" stopColor="#FFCA95" stopOpacity="0.7787" />
        <stop offset="0.1103" stopColor="#FFCD9C" stopOpacity="0.838" />
        <stop offset="0.1854" stopColor="#FFCE9E" />
        <stop offset="0.4334" stopColor="#FFA853" stopOpacity="0.5651" />
        <stop offset="0.6518" stopColor="#FF8A17" stopOpacity="0.182" />
        <stop offset="0.7556" stopColor="#FF7E00" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3007_12795"
        x1="27.0095"
        y1="8.56889"
        x2="29.8842"
        y2="5.69421"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.2822" stopColor="#FFAF62" stopOpacity="0.6832" />
        <stop offset="0.5271" stopColor="#FFEAD6" stopOpacity="0.6" />
        <stop offset="0.549" stopColor="#FFE2C6" stopOpacity="0.641" />
        <stop offset="0.5915" stopColor="#FFD6AE" stopOpacity="0.7204" />
        <stop offset="0.6361" stopColor="#FFCD9D" stopOpacity="0.8039" />
        <stop offset="0.684" stopColor="#FFC892" stopOpacity="0.8936" />
        <stop offset="0.7408" stopColor="#FFC68F" />
        <stop offset="0.795" stopColor="#FFB369" stopOpacity="0.8841" />
        <stop offset="0.9153" stopColor="#FF830A" stopOpacity="0.6266" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_3007_12795"
        x1="1.60818"
        y1="31.983"
        x2="8.59308"
        y2="24.9982"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.2822" stopColor="#FFAF62" stopOpacity="0.6832" />
        <stop offset="0.5271" stopColor="#FFEAD6" stopOpacity="0.6" />
        <stop offset="0.549" stopColor="#FFE2C6" stopOpacity="0.641" />
        <stop offset="0.5915" stopColor="#FFD6AE" stopOpacity="0.7204" />
        <stop offset="0.6361" stopColor="#FFCD9D" stopOpacity="0.8039" />
        <stop offset="0.684" stopColor="#FFC892" stopOpacity="0.8936" />
        <stop offset="0.7408" stopColor="#FFC68F" />
        <stop offset="0.795" stopColor="#FFB369" stopOpacity="0.8841" />
        <stop offset="0.9153" stopColor="#FF830A" stopOpacity="0.6266" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_3007_12795"
        x1="9.34225"
        y1="29.4408"
        x2="6.18248"
        y2="32.6006"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.2822" stopColor="#FFAF62" stopOpacity="0.6832" />
        <stop offset="0.5271" stopColor="#FFEAD6" stopOpacity="0.6" />
        <stop offset="0.549" stopColor="#FFE2C6" stopOpacity="0.641" />
        <stop offset="0.5915" stopColor="#FFD6AE" stopOpacity="0.7204" />
        <stop offset="0.6361" stopColor="#FFCD9D" stopOpacity="0.8039" />
        <stop offset="0.684" stopColor="#FFC892" stopOpacity="0.8936" />
        <stop offset="0.7408" stopColor="#FFC68F" />
        <stop offset="0.795" stopColor="#FFB369" stopOpacity="0.8841" />
        <stop offset="0.9153" stopColor="#FF830A" stopOpacity="0.6266" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint6_linear_3007_12795"
        x1="16.5016"
        y1="31.983"
        x2="23.4865"
        y2="24.9982"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.2822" stopColor="#FFAF62" stopOpacity="0.6832" />
        <stop offset="0.5271" stopColor="#FFEAD6" stopOpacity="0.6" />
        <stop offset="0.549" stopColor="#FFE2C6" stopOpacity="0.641" />
        <stop offset="0.5915" stopColor="#FFD6AE" stopOpacity="0.7204" />
        <stop offset="0.6361" stopColor="#FFCD9D" stopOpacity="0.8039" />
        <stop offset="0.684" stopColor="#FFC892" stopOpacity="0.8936" />
        <stop offset="0.7408" stopColor="#FFC68F" />
        <stop offset="0.795" stopColor="#FFB369" stopOpacity="0.8841" />
        <stop offset="0.9153" stopColor="#FF830A" stopOpacity="0.6266" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint7_linear_3007_12795"
        x1="24.2396"
        y1="29.4349"
        x2="21.0797"
        y2="32.5947"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.2822" stopColor="#FFAF62" stopOpacity="0.6832" />
        <stop offset="0.5271" stopColor="#FFEAD6" stopOpacity="0.6" />
        <stop offset="0.549" stopColor="#FFE2C6" stopOpacity="0.641" />
        <stop offset="0.5915" stopColor="#FFD6AE" stopOpacity="0.7204" />
        <stop offset="0.6361" stopColor="#FFCD9D" stopOpacity="0.8039" />
        <stop offset="0.684" stopColor="#FFC892" stopOpacity="0.8936" />
        <stop offset="0.7408" stopColor="#FFC68F" />
        <stop offset="0.795" stopColor="#FFB369" stopOpacity="0.8841" />
        <stop offset="0.9153" stopColor="#FF830A" stopOpacity="0.6266" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_3007_12795"
        x1="12.3593"
        y1="28.4461"
        x2="5.37448"
        y2="35.4309"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.3421" stopColor="#F66800" stopOpacity="0.8296" />
        <stop offset="0.5271" stopColor="#F25D00" />
        <stop offset="0.6725" stopColor="#F56500" stopOpacity="0.8548" />
        <stop offset="0.8959" stopColor="#FE7A00" stopOpacity="0.6317" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
      <linearGradient
        id="paint9_linear_3007_12795"
        x1="27.3444"
        y1="28.4461"
        x2="20.3595"
        y2="35.4309"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.092724" stopColor="#FF7E00" stopOpacity="0.6" />
        <stop offset="0.3421" stopColor="#F66800" stopOpacity="0.8296" />
        <stop offset="0.5271" stopColor="#F25D00" />
        <stop offset="0.6725" stopColor="#F56500" stopOpacity="0.8548" />
        <stop offset="0.8959" stopColor="#FE7A00" stopOpacity="0.6317" />
        <stop offset="0.9277" stopColor="#FF7E00" stopOpacity="0.6" />
      </linearGradient>
    </defs>
  </svg>
);

MaternalCapitalIcon.displayName = 'MaternalCapitalIcon';
