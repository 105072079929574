/* eslint-disable max-lines */
import { IIdenticalOffers } from './identicalOffers';
import { EHeatingType as DEPRECATED_EHeatingType } from '../../../../repositories/offer-card/entities/building/BuildingSchema';
import { EEstateType, IJsonQuery } from '../../common/json_query';
import { IModel, IModelResponse } from '../../types/model';

export type TGetOfferDataModel = IModel<IGetOfferDataRequest, TGetOfferDataResponse>;

export type THomeownerProof =
  /** Прошёл идентификацию по паспорту */
  | 'passport'
  /** Прошёл проверку в Росреестре */
  | 'rosreestr'
  /** Зарегистрирован в ФНС как самозанятый */
  | 'selfEmployed';

export type TGetOfferDataResponse = IGetOfferDataResponse200 | IGetOfferDataResponse400;

export interface IGetOfferDataResponse200 extends IModelResponse<IGetOfferDataResponseSchema> {
  statusCode: 200;
}

export interface IGetOfferDataResponse400 extends IModelResponse<IErrorResponseSchema> {
  statusCode: 400;
}

export interface IGetOfferDataRequest {
  anonymousUserId: string | undefined;
  dealType: 'sale' | 'rent';
  offerId: number;
  offerType: 'flat' | 'suburban' | 'commercial';
  realtyUserId: number | undefined;
  subdomain: string;
}

export interface IErrorResponseSchema {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any[];
  message: string;
}

export interface IGetOfferDataResponseSchema {
  agent: IAgentSchema | null;
  agentReviews?: IAgentReviewsSchema | null;
  breadcrumbs: IBreadcrumbItemSchema[] | null;
  bti: IBtiDataSchema | null;
  businessShoppingCenter: IBusinessShoppingCenterSchema | null;
  company: ICompanySchema | null;
  degradationInfo: IDegradationInfoSchema | null;
  demandJsonQuery: IJsonQuery | null;
  // Поля нет в Свагере
  flocktoryCategory: IFlocktoryCategory[] | null;
  isFavorite: boolean;
  // Информация о коттеджном поселке, связанном с объявлением
  kp: IKPSchema | null;
  linkToMap: string | null;
  newObject: INewObjectSchema | null;
  notes: INotesSchema | null;
  offences: IOffenceSchema[] | null;
  offer: IOfferSchema;
  moderationVerification: IModerationVerificationSchema;
  priceChanges?: IPriceChangeSchema[];
  seoLinks: ISeoLinkSchema[] | null;
  seoData?: ISeoDataSchema;
  similarNewObjects: INearbyNewbuildingOfferSchema[] | null;
  similarOffersMapQs: string;
  specialProject: ISpecialProjectItemSchema | null;
  stats: IOfferStatsSchema;
  subscription: IUserSubscriptionSchema | null;
  title: string;
  user: IUserSchema | null;
  agentAvailability: IAgentAvailabilitySchema | null;
  isHiddenByUser?: boolean | null;
  countrysideProjects: ICountrysideProjectsSchema | null;
  tour?: ITourSchema | null;
  priceHistoryChartData: IPriceHistoryChartDataSchema;
  identicalOffers?: IIdenticalOffers | null;
  costEstimationData?: ICostEstimateDesktopSchema | null;
}

/**
 * Данные для блока "Оценка этой квартиры от Циан"
 * */
export interface ICostEstimateDesktopSchema {
  /** Ссылка на калькулятор оценки **/
  calculatorLink?: string | null;
  /** Средняя цена за квадратный метр в этом доме **/
  estimatedPrice?: string | null;
}

/**
 * Данные для графика в блоке "Оценка этой квартиры от Циан"
 * https://jira.cian.tech/browse/CD-77072
 */
export interface IPriceHistoryChartDataSchema {
  /** Крайняя правая дата на графике **/
  datesLabels?: string[] | null;
  /** Упорядоченный список цен на каждый день в рублях **/
  historyPrices?: number[] | null;
  /** Наибольшая цена на вертикальной оси графика **/
  maxChartPrice?: number | null;
  /** Наименьшая цена на вертикальной оси графика **/
  minChartPrice?: number | null;
  /** Упорядоченный список оценок на каждый день в рублях **/
  valuationPrices?: number[] | null;
  /** Шаг вертикальной шкалы цен**/
  priceLabelStep?: number;
}

export type TBargainTermsPriceType = 'all' | 'hectare' | 'sotka' | 'squareMeter';
export type TBargainTermsPaymentPeriod = 'monthly' | 'daily';

export interface ITourSchema {
  /** Данные для блока "Экскурсия по квартире" **/
  excursion: IExcursionSchema;
  /** Данные для встраивания **/
  helmet: IHelmetSchema;
  /** Данные для плитки "Интерактивный план" **/
  interactivePlan: IInteractivePlanSectonSchema;
  /** Данные для плитки "Планировка" **/
  plan: IPlanSectonSchema;
  /** Данные для плитки "Тур" **/
  tour: ITourSectonSchema;
  /** Данные для плитки "Видео" **/
  video: IVideoSectonSchema;
}

export interface IExcursionSchema {
  /** Данные для поля "Смотреть все фото" **/
  allPhotos: IAnchorSchema;
  /** Ссылка на раздел тура **/
  anchors: IAnchorSchema[];
  /** undefined **/
  media: IMediaUrlSchema[];
  /** Превью **/
  thumbnail: string;
  /** Ссылка на страницу с экскурсией по квартире **/
  url: string;
}

export interface IMediaUrlSchema {
  /** Ссылка **/
  url: string;
}

export interface IAnchorSchema {
  /** Текст ссылки **/
  text: string;
  /** Ссылка **/
  url: string;
}

export interface IHelmetSchema {
  /** Скрипт **/
  scripts: IHelmetScriptSchema[];
}

export interface IHelmetScriptSchema {
  /** Доп. аттрибуты **/
  attributes: IAttributesSchema;
  /** Содержимое **/
  content?: string | null;
  /** Ключ **/
  key: string;
}

export interface IAttributesSchema {
  /** Ссылка на скрипт **/
  src: string;
}

export interface IInteractivePlanSectonSchema {
  /** Превью **/
  thumbnail: string;
}

export interface IPlanSectonSchema {
  /** Превью **/
  thumbnail: string;
  /** Ссылка **/
  url: string;
}

export interface ITourSectonSchema {
  /** Превью **/
  thumbnail: string;
  /** Ссылка **/
  url: string;
}

export interface IVideoSectonSchema {
  /** Общее кол-во изображений **/
  amount: number;
  /** Превью **/
  thumbnail: string;
  /** Ссылка **/
  url: string;
}

export interface IAgentReviewsSchema {
  entityRate: number | null;
  totalCount: number;
}

export interface IModerationVerificationSchema {
  constraint?: IVerificationForUserV2Schema;
  enabled: boolean;
}

export interface IVerificationForUserV2Schema {
  announcementAddress?: string;
  announcementId?: number;
  id?: number;
  state: 'failed' | 'inProgress' | 'notSpecified' | 'passed' | 'refused' | 'underValidation';
  constraint?: IConstraintV2Schema;
  canRetry: boolean;
  text: string;
}

export interface IConstraintV2Schema {
  code: 'invisibleForAll' | 'publicationProhibition' | 'visibleForRealtorsOnly';
  id: number;
  name: string;
}

export interface IAgentSchema {
  absoluteAvatarUrl: string | null;
  accountType: TAccountType | null;
  availableServices: IUserAvailableServicesSchema | null;
  bank?: string | null;
  canShowOnline?: boolean | null;
  cianUserId: number | null;
  companyName: string | null;
  creationDate: string;
  description: string | null;
  experience: string | null;
  firstName: string | null;
  id: number;
  isCianPartner?: boolean | null;
  isDeveloper: boolean | null;
  isHidden: boolean; // Профиль скрыт
  isMessagingEnabled: boolean | null;
  isPassportVerified: boolean | null;
  isPrivateBroker: boolean | null;
  isPro: boolean | null;
  isRecidivist: boolean | null;
  isSubAgent: boolean | null;
  isUserOffersHasErrors: boolean | null;
  lastName: string | null;
  masterAgent: IMasterAgentSchema | null;
  name: string | null;
  offersCount: number | null;
  offersLink: string | null;
  parentCianId: number | null;
  parentId: number | null;
  phones: IAgentPhoneSchema[] | null;
  realtyUserId: number | null;
  removeCompetitor: boolean | null;
  skills: IAgentSkillSchema[] | null;
  status: TAgentStatus | null;
  tariff: string | null;
  userTrustLevel: TUserTrustLevel | null;
  userType: TUserType | null;
  /** Текстовое представление типа аккаунта (сейчас только для аккаунтов, которые реализуют электронные торги) **/
  formattedPublisherType?: string;
}

export interface IMasterAgentSchema {
  absoluteAvatarUrl: string | null;
  absoluteMiniAvatarUrl: string | null;
  cianUserId: number | null;
  realtyUserId: number | null;
  experience: string | null;
}

export type TUserType =
  | 'agency'
  | 'developer'
  | 'developer_company'
  | 'official_representative_agency'
  | 'official_representative_company'
  | 'other'
  | 'physical'
  | 'realtor_based'
  | 'realtor_not_commerce'
  | 'realtor_prof';
export type TAccountType = 'specialist' | 'agency' | 'managementCompany' | 'rentDepartment';

export interface IUserAvailableServicesSchema {
  isPhoneChangingAvailable: boolean | null;
  isCallTrackingAvailable: boolean | null;
}

export interface IAgentSkillSchema {
  id: number | null;
  name: string | null;
  parentName: string | null;
}

export interface IAgentPhoneSchema {
  // Код оператора
  code: string | null;
  confirmed: boolean | null;
  countryCode: string | null;
  id: number | null;
  number: number | null;
  // Опубликован
  visible: boolean | null;
}

export type TUserTrustLevel = 'notInvolved' | 'involved' | 'new' | 'excluded' | 'danger';

export type TAgentStatus = 'published' | 'hidden';

export interface IBreadcrumbItemSchema {
  title: string;
  url?: string;
}

export interface IDistrictBTIDataSchema {
  avgHouseAge: number;
  districtTitle: string;
  population: number;
  priceSqm: number;
  priceSqmDiff: number;
  regionTitle: string;
  roomRentPrice: number;
  roomRentPriceDiff: number;
  roomSalePrice: number;
  roomSalePriceDiff: number;
}

export type THouseMaterial =
  | 'block'
  | 'boards'
  | 'brick'
  | 'custom'
  | 'monolith'
  | 'monolithBrick'
  | 'none'
  | 'old'
  | 'panel'
  | 'stalin'
  | 'wood';

export interface IRatingHouseSchema {
  flatQuality?: number;
  houseQuality?: number;
}

export interface IRatingLocationSchema {
  ecologyIndex?: number;
  infrastructureIndex?: number;
  safetyIndex?: number;
  surroundBad?: number;
  surroundPlus?: number;
  transportIndex?: number;
}

enum EHeatingType {
  /** Есть */
  Has = 'has',
  /** Не указано */
  None = 'none',
  /** Нет (без отопления) */
  Without = 'without',
  /** Центральное газовое */
  Gas = 'gas',
  /** Центральное угольное */
  Coal = 'coal',
  /** Печь */
  Stove = 'stove',
  /** Камин */
  Fireplace = 'fireplace',
  /** Электрическое */
  Electric = 'electric',
  /** Автономное */
  Autonomous = 'autonomous',
  /** Котел | Квартирное отопление (квартирный котел) */
  Boiler = 'boiler',
  /** Центральное отопление */
  Central = 'central',
  /** Другое */
  Other = 'other',
  /** Автономное газовое */
  AutonomousGas = 'autonomousGas',
  /** Дизельное */
  Diesel = 'diesel',
  /** Твердотопливный котел */
  SolidFuelBoiler = 'solidFuelBoiler',
  /** Индивидуальный тепловой пункт (ИТП) */
  ITP = 'itp',
  /** Индивидуальный тепловой пункт (ИТП) */
  GasWaterHeater = 'gasWaterHeater',
  /** Автономная котельная (крышная, встроенно-пристроенная) */
  AutonomousBoiler = 'autonomousBoiler',
}

enum EOverhaulFundType {
  /** Не известно */
  Unknown = 'unknown',
  /** На счете регионального оператора */
  RegionalOp = 'regionalOp',
  /** На специальном счете организации */
  SpecialOrg = 'specialOrg',
  /** На специальном счете у регионального оператора */
  SpecialRegionalOp = 'specialRegionalOp',
  /** Не определён */
  Undefined = 'undefined',
}

enum EGasSupplyType {
  /** Не известно */
  Unknown = 'unknown',
  /** Автономное */
  Autonomous = 'autonomous',
  /** Центральное */
  Central = 'central',
  /** Отсутствует */
  Without = 'without',
}

enum EOverlapType {
  /** Не известно */
  Unknown = 'unknown',
  /** Деревянные */
  Wood = 'wood',
  /** Железобетонные */
  Concrete = 'concrete',
  /** Смешанные */
  Mixed = 'mixed',
  /** Иные */
  Other = 'other',
}

export interface IHouseBTIDataSchema {
  entrances?: number;
  flatCount?: number;
  floorMax?: number;
  fullName: string;
  houseMaterialType?: THouseMaterial;
  isEmergency?: boolean;
  priceCian?: number;
  priceCianMin?: number;
  priceCianMax?: number;
  priceSqm?: number;
  priceSqmDiff?: number;
  priceSqmMaxCadastr?: number;
  ratingHouse?: IRatingHouseSchema;
  ratingLocation?: IRatingLocationSchema;
  roomRentPrice?: number;
  roomRentPriceDiff?: number;
  shortName: string;
  url: string;
  yearRelease?: number;
  /** Тип отопления */
  houseHeatSupplyType: EHeatingType | null;
  /** Способ формирования фонда капитального ремонта */
  houseOverhaulFundType: EOverhaulFundType | null;
  /** Тип системы газоснабжения */
  houseGasSupplyType: EGasSupplyType | null;
  /** Тип перекрытий */
  houseOverlapType: EOverlapType | null;
  /** Количество лифтов */
  lifts: number | null;
  /** Серия дома */
  seriesName: string | null;
}

export interface IBtiDataSchema {
  districtData: IDistrictBTIDataSchema | null;
  houseData: IHouseBTIDataSchema | null;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICompanySchema {}

export interface IDegradationInfoSchema {
  agent: boolean | null;
  agentReviews: boolean | null;
  btiData: boolean | null;
  businessShoppingCenter: boolean | null;
  company: boolean | null;
  isFavorite: boolean | null;
  newObject: boolean | null;
  notes: boolean | null;
  offences: boolean | null;
  similarOffers: boolean | null;
  stats: boolean | null;
  subscription: boolean | null;
  user: boolean | null;
  demandJsonQuery: boolean | null;
}

export interface IFlocktoryCategory {
  key: string;
  value: number;
}

export type TNewObjectRoomType =
  | 'bed'
  | 'fiveRoom'
  | 'fourRoom'
  | 'large'
  | 'oneRoom'
  | 'openPlan'
  | 'room'
  | 'share'
  | 'studio'
  | 'threeRoom'
  | 'twoRoom';

export interface IRoomInfoSchema {
  maxPrice: number | null;
  minArea: number | null;
  minPrice: number | null;
  offersCount: number | null;
  type: TNewObjectRoomType | null;
  url: string | null;
}

export interface IStatSchema {
  text: string | null;
  qs: string | null;
}

export interface IStatsNewojectSchema {
  done: IStatSchema | null;
  progress: IStatSchema | null;
}

export interface INewObjectBuilderSchema {
  logoUrl: string | null;
  name: string | null;
  stats: IStatsNewojectSchema | null;
  url: string | null;
}

export interface INewObjectSchema {
  developer: INewObjectBuilderSchema | null;
  hasLayoutImagesOffers: boolean | null;
  id: number | null;
  name: string;
  roomsInfo: IRoomInfoSchema[] | null;
  status: string | null;
  totalCountOfferInNewobject: number | null;
  url: string | null;
  newbuildingFeatures: INewBuildingFeaturesSchema | null;
}

export interface INewBuildingFeaturesSchema {
  imagesCount: number;
  videosCount: number;
  reviewsCount: number;
  firstImageUrl?: string;
  hasProgressOfConstructions: boolean;
}

export interface INoteItemSchema {
  text: string;
}

export interface INotesSchema {
  offer: INoteItemSchema | null;
}

export interface IOffenceSchema {
  reason: string;
  comment?: string;
}

export interface IBookingData {
  bookingDuration: number;
  cost: number;
  externalId: string; // id объявления ПИКа
  status: 'inReserve' | 'free';
  similarOffersUrl?: string;
}

// Провайдер услуги подмены номера
export type TCalltrackingProvider = 'beeline' | 'mtt' | 'mts' | null;

export type TRosreestrCheckStatus =
  // Проверка на стороне росреестра
  | 'inProgress'
  // Проверка прошла успешно
  | 'success'
  // Проверка прошла неуспешно
  | 'fail';

export interface IRosreestrCheck {
  status: TRosreestrCheckStatus;
}

export interface IOfferFlags {
  /** Флаг Архива объявления */
  isArchived?: boolean;
  /** Флаг Сдаётся с ЦИАН-помощником */
  isDealRent?: boolean;
}

export interface IAreaPart {
  price?: number;
  area?: number;
}

/** Типы коворкингов */
export enum ECoworkingOfferType {
  /** Гибкий офис */
  Office = 'office',
  /** Закрепленное рабочее место */
  FixedWorkplace = 'fixedWorkplace',
  /** Незакрепленное рабочее место */
  FlexibleWorkplace = 'flexibleWorkplace',
  /** Конференц-зал */
  ConferenceHall = 'conferenceHall',
  /** Переговорная комната */
  MeetingRoom = 'meetingRoom',
}

/** Рабочее время коворкинга */
export interface ICoworkingWorkTime {
  /** Работает с */
  timeFrom: string;
  /** Работает до */
  timeTo: string;
}

export type ICoworkingAmenityNames =
  | 'wifi'
  | 'bikeParking'
  | 'internet'
  | 'restZone'
  | 'cafe'
  | 'cleaning'
  | 'kitchen'
  | 'furniture'
  | 'security'
  | 'parking'
  | 'meetingRoom'
  | 'printer'
  | 'reception'
  | 'modern'
  | 'gym'
  | 'phoneBooth'
  | 'techSupport'
  | 'events'
  | 'fax'
  | 'flipchart'
  | 'fruits'
  | 'tea'
  | 'lockers'
  | 'legalAddress';

export interface ICoworkingAmenity {
  key: ICoworkingAmenityNames;
  title: string;
}

/** Объект коворкинга */
export interface ICoworking {
  /** ID коворкинга */
  id: number;
  /** Ссылка на коворкинг */
  url?: string;
  /** Название */
  name?: string;
  /** Доступ - С понедельника по пятницу | Ежедневно */
  accessType?: 'weekdays' | 'daily';
  /** Круглосуточно */
  isRoundTheClock?: boolean;
  /** Рабочее время */
  workTime?: ICoworkingWorkTime;
  /** Включенные Удобства */
  includedAmenities?: ICoworkingAmenity[];
  /** Невключенные Удобства */
  notIncludedAmenities?: ICoworkingAmenity[];
}

export enum EUserTrustLevel {
  /** Очень много жалоб **/
  Danger = 'danger',
  /** Исключен из программы **/
  Excluded = 'excluded',
  /** участвует **/
  Involved = 'involved',
  /** Новый пользователь **/
  New = 'new',
  /** не участвует **/
  NotInvolved = 'notInvolved',
}

export enum EReadyBusinessType {
  /** Арендный бизнес */
  RentalBusiness = 'rentalBusiness',
  /** Другое */
  Other = 'other',
  /** Готовый бизнес */
  ReadyBusiness = 'readyBusiness',
}

export interface IMonthlyIncome {
  currency: TCurrency;
  income: number;
}

export interface IOfferSchema {
  accessType: TAccessType | null;
  added: string;
  humanizedEditDate?: string;
  allRoomsArea: string | null;
  areaParts?: IAreaPart[];
  availableFrom: string | null;
  balconiesCount: number | null;
  bargainTerms: IBargainTermsSchema;
  bedroomsCount: number | null;
  bedsCount: number | null;
  bestPlaceAnalyticsAvailable: boolean | null;
  blackFriday?: IBlackFridayPromoSchema;
  booking: IBookingData | null;
  building: IBuildingSchema | null;
  businessShoppingCenter: IBusinessShoppingCenterSchema | null;
  callTrackingProvider: TCalltrackingProvider;
  canParts: boolean;
  cargoLiftsCount: number | null;
  categoriesIds: number[] | null;
  category: TCategory;
  childrenAllowed: boolean | null;
  cianId: number;
  cianUserId: number | null;
  combinedWcsCount: number | null;
  conditionType: TConditionType | null;
  coworkingOfferType?: ECoworkingOfferType | null;
  coworking?: ICoworking;
  deadline: IDeadlineSchema | null;
  dealRentVersion: string | null;
  dealType: TDealType;
  decoration: TDecorationType | null;
  demolishedInMoscowProgramm: boolean | null;
  description: string | null;
  drainage: IDrainageSchema | null;
  drivewayType: TDrivewayType | null;
  editPhoto: string | null;
  electricity: IElectricitySchema | null;
  electricityPower: number | null;
  estateType: EEstateType | null;
  exportLinks: IExportLinksSchema;
  flags: IOfferFlags | null;
  flatType: TFlatType | null;
  floorFrom?: number | null;
  floorMaterialType: TFloorMaterial | null;
  floorNumber: number;
  floorTo?: number | null;
  furniturePresence: TFurniturePresence | null;
  propertyType: TPropertyType | null;
  garage: IGarageSchema | null;
  geo: IGeoSchema;
  gas: IGasSchema | null;
  hasLight: boolean | null;
  hasExtinguishingSystem: boolean | null;
  hasBathhouse: boolean | null;
  hasBathtub: boolean | null;
  hasConditioner: boolean | null;
  hasDishwasher: boolean | null;
  hasDrainage: boolean | null;
  hasElectricity: boolean | null;
  hasEncumbrances: boolean | null;
  hasEquipment: boolean | null;
  hasFridge: boolean | null;
  hasFurniture: boolean | null;
  hasGarage: boolean | null;
  hasGas: boolean | null;
  hasHeating: boolean | null;
  hasInternet: boolean | null;
  hasInvestmentProject: boolean | null;
  hasKitchenFurniture: boolean | null;
  hasLift: boolean | null;
  buildersIds: number[] | null;
  hasParking: boolean | null;
  hasPhone: boolean | null;
  hasPool: boolean | null;
  hasSafeCustody: boolean | null;
  hasSecurity: boolean | null;
  hasShopWindows: boolean | null;
  hasShower: boolean | null;
  hasTransportServices: boolean | null;
  hasTv: boolean | null;
  hasWasher: boolean | null;
  hasWater: boolean | null;
  id: number;
  inputType: TInputType | null;
  isApartment: boolean | null;
  isApartments: boolean | null;
  isByHomeowner: boolean | null;
  isCustoms: boolean | null;
  isDemolishedInMoscowProgramm: boolean | null;
  isEnabledCallTracking: boolean | null;
  isFromBuilder: boolean | null;
  isFromSeller: boolean | null;
  isImported: boolean | null;
  isInHiddenBase: boolean | null;
  isLayoutApproved: boolean | null;
  isLegalAddressProvided: boolean | null;
  isNeedHideExactAddress?: boolean | null;
  isOccupied: boolean | null;
  isPenthouse: boolean | null;
  isRentByParts: boolean | null;
  isResale: boolean | null;
  kitchenArea: number | null;
  land: ILandSchema | null;
  layout: TLayout | null;
  livingArea: number | null;
  loggiasCount: number | null;
  maxArea: string | null;
  minArea: string | null;
  minPriceTotalEur: number | null;
  minPriceTotalPerMonthRur: number | null;
  minPriceTotalRur: number | null;
  minPriceTotalUsd: number | null;
  monthlyIncome?: IMonthlyIncome | null;
  newEdit: string | null;
  newbuilding?: INewBuildingSchema;
  offerType: TOfferType;
  passengerLiftsCount: number | null;
  permittedUseType: TPermittedUseType | null;
  petsAllowed: boolean | null;
  phones: IPhoneSchema[] | null;
  photos: IPhotoSchema[] | null;
  placementType: TPlacement | null;
  possibleToChangePermittedUseType: boolean | null;
  pricesPerSquareMeter: TPricesMonthly | null;
  pricesPerSquareMeterPerYear: TPricesMonthly | null;
  pricePerUnitArea: number | null;
  pricePerUnitAreaRur: number | null;
  pricePerUnitAreaEur: number | null;
  pricePerUnitAreaPerYearRur: number | null;
  pricePerUnitAreaUsd: number | null;
  priceTotalEur: number | null;
  priceTotalPerMonthEur: number | null;
  priceTotalPerMonthRur: number | null;
  priceTotalPerMonthUsd: number | null;
  priceTotalRur: number | null;
  priceTotalUsd: number | null;
  promoLabel: string | null;
  publicationDate: number;
  publishedUserId: number | null;
  readyBusinessType?: EReadyBusinessType;
  recoveryLink: string | null;
  rentByPartsDescription: string | null;
  repairType: TRepair | null;
  roomArea: number | null;
  roomType: TRoomType | null;
  roomsCount: number;
  roomsForSaleCount: number | null;
  // Состояние проверки собственника в Росреестре
  rosreestrCheck?: IRosreestrCheck | null;
  separateWcsCount: number | null;
  shareAmount: string | null;
  similarOffersCount: number | null;
  similarOffersLink: string | null;
  specialty: ICommercialSpecialtySchema | null;
  status: TOfferStatus;
  taxNumber: number | null;
  title: string | null;
  totalArea: string | null;
  trackingData: ITrackingDataSchema | null;
  userId: number | null;
  valueAddedServices: IValueAddedServicesSchema | null;
  videos: IVideoSchema[] | null;
  minVatPriceTotalPerMonthRur: number | null;
  vatPriceTotalPerMonthRur: number | null;
  minVatPriceTotalRur: number | null;
  vatPriceTotalRur: number | null;
  water: IWaterSchema | null;
  waterPipesCount: number | null;
  wcLocationType: TWcLocation | null;
  wcType: TWсType | null;
  wcsCount: number | null;
  windowsViewType: TWindowsView | null;
  workTimeInfo?: IWorkTimeInfoSchema;
  /** Количество рабочих мест */
  workplaceCount?: number | null;
  /** Подменен ли номер телефона */
  isDealRequestSubstitutionPhone: boolean;
  /** Список проверок, пройденных пользователем */
  homeownerProofs: Array<THomeownerProof> | null;
  /** Объявление было исправлено юзером */
  userConfirmedFix: boolean;
  /** Показывать ли предупреждение о недопустимости предоплаты **/
  showWarningMessage: boolean;
  /** Программа доверия **/
  userTrust: string;
  /** Программа доверия **/
  userTrustLevel: EUserTrustLevel;
  /** Информация о доме **/
  house?: IHouseSchema;
  /** Ссылка на торги на электронной площадке **/
  tradingLink?: string;
  /** Наличие льготной ипотеки **/
  hasDiscountMortgage?: boolean;
  /** Кадастровый номер **/
  cadastralNumber?: string;
  /** Кадастровый номер дома **/
  buildingCadastralNumber?: string;
}

export type TCurrency = 'eur' | 'rur' | 'usd';

export type TAccessType = 'free' | 'passSystem';

/**
 * Тип сделки в коммерческой
 * leaseAssignment - ППА (переуступка права аренды)
 * sale - свободная продажа
 */
export type TContractType = 'leaseAssignment' | 'sale';

export interface IBargainTermsSchema {
  agentBonus: IAgentBonusSchema | null;
  agentFee: number | null;
  bargainAllowed: boolean | null;
  clientFee: number | null;
  /* Тип сделки в коммерческой */
  contractType: TContractType | null;
  currency: TCurrency;
  depositPrices: IPriceSchema | null;
  hasGracePeriod: boolean | null;
  includedOptions: TIncludedOption[] | null;
  leaseTermType: 'fewMonths' | 'longTerm' | null;
  leaseType: TLeaseType | null;
  minLeaseTerm: number | null;
  mortgageAllowed: boolean | null;
  paymentPeriod: TPaymentPeriod | null;
  prepayMonths: number | null;
  price: number;
  priceType: 'all' | 'hectare' | 'sotka' | 'squareMeter';
  prices: IPriceSchema;
  /** Цена за рабочее место */
  priceForWorkplace?: number | null;
  saleType: 'alternative' | 'dupt' | 'dzhsk' | 'free' | 'fz214' | 'investment' | 'pdkp' | null;
  securityDepositPrices: IPriceSchema | null;
  tenantsType: TTenantsType | null;
  utilitiesTerms: IUtilitiesTermsSchema | null;
  utilitiesTermsPrices: IPriceSchema;
  vatType: TVatType | null;
  vatPrices: IPriceSchema | null;
}

export interface IBlackFridayPromoSchema {
  isExclusive: boolean;
  label: string;
}

export interface IAgentBonusSchema {
  currency: TCurrency | null;
  paymentType: 'fixed' | 'percent' | null;
  value: number | null;
}

export interface IPriceSchema {
  eur: number;
  rur: number;
  usd: number;
}

export type TIncludedOption = 'operationalCosts' | 'utilityCharges';

export type TLeaseType = 'direct' | 'jointVenture' | 'sublease';

export type TPaymentPeriod = 'annual' | 'monthly';

export type TTenantsType = 'any' | 'family' | 'male' | 'female';

export interface IUtilitiesTermsSchema {
  includedInPrice: boolean;
  price: number;
  flowMetersNotIncludedInPrice?: boolean | null;
}

export type TVatType = 'included' | 'notIncluded' | 'usn';

export interface IBuildingSchema {
  buildYear: number | null;
  cargoLiftsCount: number | null;
  ceilingHeight: string | null;
  classType: TBuildingClassType | null;
  conditionRatingType: TConditionRatingType | null;
  cranageTypes: ICranageTypeSchema[] | null;
  developer: string | null;
  gatesType: TGatesType | null;
  hasGarbageChute: boolean | null;
  houseLineType: THouseLine | null;
  columnGrid: string | null;
  infrastructure: ICommercialBuildingInfrastructureSchema | null;
  floorsCount: number | null;
  heatingType: THeatingType | DEPRECATED_EHeatingType | null;
  liftTypes: ILiftTypeSchema[] | null;
  managementCompany: string | null;
  materialType: TMaterialType | null;
  name: string | null;
  openingHours: IOpeningHoursSchema | null;
  parking: IParkingSchema | null;
  passengerLiftsCount: number | null;
  shoppingCenterScaleType: TShoppingCenterScaleType | null;
  statusType: TBuildingStatusType | null;
  tenants: string | null;
  totalArea: string | null;
  type: TBuildingType | null;
  workingDaysType: TWorkingDaysType | null;
  ventilationType: TVentilationType | null;
  conditioningType: TConditioningType | null;
  extinguishingSystemType: TExtinguishingSystemType | null;
}

export type TBuildingClassType = 'bMinus' | 'aPlus' | 'bPlus' | 'cPlus' | 'a' | 'b' | 'c' | 'd';

export type TConditionRatingType = 'excellent' | 'good' | 'satisfactory';

export type TCranageType = 'beam' | 'gantry' | 'overhead' | 'railway';

export interface ICranageTypeSchema {
  type: TCranageType;
  count: number | null;
  loadCapacity: number | null;
}

export type TGatesType = 'atZero' | 'dockType' | 'onRamp';

export type THouseLine = 'first' | 'other' | 'second';

export interface ICommercialBuildingInfrastructureSchema {
  hasCarWash: boolean;
  hasCarService: boolean;
  hasPharmacy: boolean;
  hasClothesStudio: boolean;
  hasAtm: boolean;
  hasPool: boolean;
  hasBuffet: boolean;
  hasExhibitionAndWarehouseComplex: boolean;
  hasHotel: boolean;
  hasCafe: boolean;
  hasCinema: boolean;
  hasConferenceRoom: boolean;
  hasMedicalCenter: boolean;
  hasMinimarket: boolean;
  hasWarehouse: boolean;
  hasNotaryOffice: boolean;
  hasBankDepartment: boolean;
  hasPark: boolean;
  hasRestaurant: boolean;
  hasBeautyShop: boolean;
  hasCanteen: boolean;
  hasSupermarket: boolean;
  hasShoppingArea: boolean;
  hasFitnessCentre: boolean;
  hasStudio: boolean;
  hasCentralReception: boolean;
  hasOfficeSpace: boolean;
  hasAquapark: boolean;
  hasBabySitting: boolean;
  hasBowling: boolean;
  hasGameRoom: boolean;
  hasSlotMachines: boolean;
  hasRink: boolean;
  hasFoodCourt: boolean;
  hasInspectionPit: boolean;
  hasVideoSurveillance: boolean;
  hasHourSecurity: boolean;
  hasAutomaticGates: boolean;
  hasEntryByPass: boolean;
  hasBasement: boolean;
  hasTire: boolean;
}

export type THeatingType =
  | 'no'
  | 'central'
  | 'autonomous'
  | 'boiler'
  | 'stove'
  | 'fireplace'
  | 'other'
  | 'central_gas'
  | 'central_coal'
  | 'autonomous_gas'
  | 'diesel'
  | 'electric'
  | 'solid_fuel_boiler';

export type TLiftType = 'cargo' | 'escalator' | 'lift' | 'passenger' | 'telpher' | 'travelator';

export interface ILiftTypeSchema {
  type: TLiftType;
  count: number | null;
  loadCapacity: number | null;
}

export type TMaterialType =
  | 'boards'
  | 'wood'
  | 'stalin'
  | 'monolith'
  | 'brick'
  | 'panel'
  | 'old'
  | 'monolithBrick'
  | 'block'
  | 'aerocreteBlock'
  | 'foamConcreteBlock'
  | 'gasSilicateBlock'
  | 'wireframe';

export type TOpeningHoursType = 'specific' | 'roundTheClock';

export interface IOpeningHoursSchema {
  from: string | null;
  to: string | null;
  type: TOpeningHoursType | null;
}

export type TParkingLocationType = 'internal' | 'external';

export type TPurposeType = 'cargo' | 'passenger';

export type TParkingType = 'roof' | 'underground' | 'ground' | 'multilevel' | 'open' | 'spontaneous';

export interface IParkingSchema {
  /** Тип валюты в поле priceMonthly priceEntry */
  currency: TCurrency | null;
  /** Доступна ли парковка */
  isAvailable: boolean | null;
  /** Бесплатная парковка, если тут true то остальные цены надо игнорировать, они могут быть не 0 */
  isFree: boolean | null;
  locationType: TParkingLocationType | null;
  /** Количество мест. Иногда в место null приходит 0 когда парковка не isAvailable */
  placesCount: number | null;
  /** Стоимость въезда в валюте currency */
  priceEntry: number | null;
  /** Стоимость въезда с конвертацией валюты */
  pricesEntry: TPricesMonthly | null;
  /** Стоимость парковки в месяц в валюте currency */
  priceMonthly: number | null;
  /** Стоимость парковки в месяц с конвертацией валюты */
  pricesMonthly: TPricesMonthly | null;
  purposeType: TPurposeType | null;
  /** Разновидности парковки подземная, надземная, воображаемая и т.д. */
  type: TParkingType | null;
}

/**
 * Разбивка стоимости парковки, если стоимость не указана то 0.
 */
export type TPricesMonthly = {
  rur: number;
  eur: number;
  usd: number;
};

export type TShoppingCenterScaleType =
  | 'district'
  | 'microdistrict'
  | 'okrug'
  | 'regional'
  | 'superOkrug'
  | 'superRegional';

export type TBuildingStatusType = 'newBuilding' | 'operational' | 'project' | 'secondary' | 'underConstruction';

export type TBuildingType =
  | 'administrativeBuilding'
  | 'businessCenter'
  | 'businessCenter2'
  | 'businessHouse'
  | 'businessPark'
  | 'businessQuarter'
  | 'businessQuarter2'
  | 'free'
  | 'freeAppointmentObject'
  | 'industrialComplex'
  | 'industrialPark'
  | 'industrialSite'
  | 'industrialWarehouseComplex'
  | 'logisticsCenter'
  | 'logisticsPark'
  | 'logisticsComplex'
  | 'mansion'
  | 'manufactureBuilding'
  | 'manufacturingFacility'
  | 'modular'
  | 'multifunctionalComplex'
  | 'officeAndHotelComplex'
  | 'officeAndResidentialComplex'
  | 'officeAndWarehouse'
  | 'officeAndWarehouseComplex'
  | 'officeBuilding'
  | 'officeCenter'
  | 'officeComplex'
  | 'officeIndustrialComplex'
  | 'officeQuarter'
  | 'old'
  | 'other'
  | 'outlet'
  | 'propertyComplex'
  | 'residentialComplex'
  | 'residentialFund'
  | 'residentialHouse'
  | 'shoppingAndBusinessComplex'
  | 'shoppingAndCommunityCenter'
  | 'shoppingAndEntertainmentCenter'
  | 'shoppingCenter'
  | 'shoppingComplex'
  | 'specializedShoppingCenter'
  | 'standaloneBuilding'
  | 'technopark'
  | 'tradeAndExhibitionComplex'
  | 'tradingHouse'
  | 'tradingOfficeComplex'
  | 'uninhabitedFund'
  | 'warehouse'
  | 'warehouseComplex';

export type TWorkingDaysType =
  | 'everyday'
  | 'friday'
  | 'monday'
  | 'saturday'
  | 'sunday'
  | 'thursday'
  | 'tuesday'
  | 'wednesday'
  | 'weekdays'
  | 'weekends';

export type TExtinguishingSystemType = 'hydrant' | 'sprinkler' | 'powder' | 'gas' | 'alarm' | 'no';

export type TConditioningType = 'local' | 'central' | 'no';

export type TVentilationType = 'natural' | 'forced' | 'no';

export interface IBusinessShoppingCenterSchema {
  id: number | null;
  buildYear: number | null;
  buildingClassType: TBuildingClassType | null;
  buildingStatusType: TBuildingStatusType | null;
  buildingType: TBuildingType | null;
  ceilingHeight: string | null;
  developers: IBscDeveloperSchema[] | null;
  escalatorCount: number | null;
  conditioningType: TConditioningType | null;
  electricity: IBscElectricitySchema | null;
  extinguishingSystemType: TExtinguishingSystemType | null;
  fromRepresentative: boolean | null;
  heatingType: THeatingType | null;
  inputType: TBSCInputType | null;
  land: IBscLandSchema | null;
  managementCompanies: IManagementCompanySchema[] | null;
  infrastructure: IInfrastructureSchema | null;
  name: string | null;
  openingHours: IBscOpeningHoursSchema | null;
  parentId: number | null;
  parentName: string | null;
  parentUrl: string | null;
  parkings: IBscParkingSchema[] | null;
  repairStateType: TRepairStateType | null;
  shoppingCenterScaleType: TShoppingCenterScaleType | null;
  siteUrl: string | null;
  totalArea: string | null;
  totalLiftCount: number | null;
  travelatorCount: number | null;
  type: TParentBuildingType | null;
  url: string | null;
  useParentName: boolean | null;
  ventilationType: TVentilationType | null;
}

export interface IBscDeveloperSchema {
  id: number | null;
  logoUrl: string | null;
  name: string | null;
}

export interface IBscElectricitySchema {
  hasUpsSystem: boolean | null;
  load: number | null;
  power: number | null;
  reliabilityType: 'first' | 'second' | 'third' | null;
}

export type TBSCInputType =
  | 'commonFromTheStreet'
  | 'commonFromTheYard'
  | 'separateFromTheStreet'
  | 'separateFromTheYard';

export type TBusinessShoppingCenterLandOwnershipType = 'owned' | 'rent';

export interface IBscLandSchema {
  area: string | null;
  ownershipType: TBusinessShoppingCenterLandOwnershipType | null;
}

export interface IManagementCompanySchema {
  id: number | null;
  logoUrl: string | null;
  name: string | null;
}

export interface IInfrastructureSchema {
  hasStudio: boolean | null;
  hasClothesStudio: boolean | null;
  hasHotel: boolean | null;
  hasMedicalCenter: boolean | null;
  hasPharmacy: boolean | null;
  hasCarService: boolean | null;
  hasMinimarket: boolean | null;
  hasWarehouse: boolean | null;
  hasCarWash: boolean | null;
  hasConferenceRoom: boolean | null;
  hasSupermarket: boolean | null;
  hasCanteen: boolean | null;
  hasAtm: boolean | null;
  hasPark: boolean | null;
  hasFitnessCentre: boolean | null;
  hasCentralReception: boolean | null;
  hasShoppingArea: boolean | null;
  hasBankDepartment: boolean | null;
  hasFoodCourt: boolean | null;
  hasBeautyShop: boolean | null;
  hasGameRoom: boolean | null;
  hasExhibitionAndWarehouseComplex: boolean | null;
  hasBuffet: boolean | null;
  hasCinema: boolean | null;
  hasNotaryOffice: boolean | null;
  hasRestaurant: boolean | null;
  hasCafe: boolean | null;
  hasPool: boolean | null;
  flatList: string[];
}

export interface IBscOpeningHoursSchema {
  roundTheClock: boolean | null;
  from: string | null;
  to: string | null;
  type: TOpeningHoursType | null;
}

export interface IBscParkingSchema {
  isFree: boolean | null;
  placesCount: number | null;
  priceHourly: number | null;
  priceMonthly: number | null;
  type: TParkingType | null;
}

export type TRepairStateType =
  | 'afterReconstruction'
  | 'cosmeticRepairsRequired'
  | 'majorRepairsRequired'
  | 'needsReconstruction'
  | 'new'
  | 'workingCondition';

export type TParentBuildingType = 'businessCenter' | 'shoppingCenter';

export type TConditionType =
  | 'cosmeticRepairsRequired'
  | 'design'
  | 'finishing'
  | 'majorRepairsRequired'
  | 'office'
  | 'typical';

export type TCategory =
  | 'townhouseSale'
  | 'buildingRent'
  | 'garageSale'
  | 'commercialLandRent'
  | 'shoppingAreaRent'
  | 'freeAppointmentObjectSale'
  | 'publicCateringSale'
  | 'houseRent'
  | 'cottageSale'
  | 'bedRent'
  | 'warehouseRent'
  | 'flatRent'
  | 'landSale'
  | 'roomRent'
  | 'officeRent'
  | 'domesticServicesRent'
  | 'industryRent'
  | 'houseShareRent'
  | 'businessRent'
  | 'carServiceRent'
  | 'buildingSale'
  | 'shoppingAreaSale'
  | 'commercialLandSale'
  | 'houseSale'
  | 'townhouseRent'
  | 'warehouseSale'
  | 'flatSale'
  | 'dailyHouseRent'
  | 'garageRent'
  | 'dailyBedRent'
  | 'publicCateringRent'
  | 'dailyFlatRent'
  | 'freeAppointmentObjectRent'
  | 'roomSale'
  | 'cottageRent'
  | 'domesticServicesSale'
  | 'flatShareSale'
  | 'officeSale'
  | 'dailyRoomRent'
  | 'industrySale'
  | 'houseShareSale'
  | 'businessSale'
  | 'carServiceSale'
  | 'newBuildingFlatSale';

export type TQuarter = 'first' | 'second' | 'third' | 'fourth';

export interface IDeadlineSchema {
  isComplete: boolean | null;
  quarter: TQuarter | null;
  quarterEnd: string | null;
  year: number | null;
}

export type TDealType = 'rent' | 'sale';

export type TOfferType = 'flat' | 'suburban' | 'commercial';

export type TPermittedUseType =
  | 'agricultural'
  | 'businessManagement'
  | 'commonUseArea'
  | 'highriseBuildings'
  | 'hotelAmenities'
  | 'individualHousingConstruction'
  | 'industry'
  | 'leisure'
  | 'lowriseHousing'
  | 'publicUseOfCapitalConstruction'
  | 'serviceVehicles'
  | 'shoppingCenters'
  | 'warehouses';

export type TDecorationType = 'without' | 'rough' | 'fine';
export type TDrivewayType = 'asphalt' | 'ground' | 'no';

export type TDrainageType = 'storm' | 'treatment_facilities' | 'septic_tank' | 'central' | 'autonomous' | 'cesspool';

export type TLocationType = 'border' | 'no' | 'location';
export interface IDrainageSchema {
  locationType: TLocationType | null;
  type: TDrainageType | null;
  capacity: number | null;
  possibleToConnect: boolean | null;
}

export interface IElectricitySchema {
  locationType: 'border' | 'location' | 'no' | null;
  possibleToConnect: boolean | null;
  power: number | null;
}

export interface IExportLinksSchema {
  docxUrl: string;
  pdfUrl: string;
}

export type TFloorMaterial =
  | 'asphalt'
  | 'concrete'
  | 'laminate'
  | 'linoleum'
  | 'polymeric'
  | 'reinforcedConcrete'
  | 'selfLeveling'
  | 'tile'
  | 'wood';

export type TFlatType = 'openPlan' | 'rooms' | 'studio';

export type TPropertyType =
  | 'land'
  | 'building'
  | 'shoppingArea'
  | 'warehouse'
  | 'office'
  | 'industry'
  | 'garage'
  | 'freeAppointment';

export type TFurniturePresence = 'no' | 'optional' | 'yes';

export type TGarageCategory = 'builtIn' | 'capital' | 'shell' | 'samostroy';

export type TGarageMaterial = 'brick' | 'metal';

export type TGarageStatus = 'byProxy' | 'cooperative' | 'ownership';

export type TGarageType = 'parkingPlace' | 'garage' | 'box';

export interface IGarageSchema {
  garageType: TGarageCategory | null;
  material: TGarageMaterial | null;
  status: TGarageStatus | null;
  type: TGarageType | null;
}

export interface IGeoSchema {
  coordinates: {
    lng: number;
    lat: number;
  };
  address: IAddressInfoSchema[];
  undergrounds: IUndergroundInfoSchema[] | null;
  highways: IHighwayInfoSchema[] | null;
}

export type TAddressType =
  | 'country'
  | 'district'
  | 'house'
  | 'location'
  | 'road'
  | 'street'
  | 'underground'
  | 'raion'
  | 'okrug';

export interface IAddressInfoSchema {
  fullName: string;
  id: number;
  name: string;
  shortName: string;
  type: TAddressType;
  url: string;
  locationTypeId: number | null;
}

export type TUndergroundTravelType = 'walk' | 'transport';
export interface IUndergroundInfoSchema {
  name: string;
  lineColor: string;
  travelTime: number | null;
  travelType: TUndergroundTravelType | null;
  url: string;
  underConstruction?: boolean;
  releaseYear?: number;
}

export interface IHighwayInfoSchema {
  name: string;
  distance: number;
  url: string;
  id: number;
}

export type TPressureType = 'high' | 'middle' | 'low';

export type TGasType = 'border' | 'gasHolder' | 'main';

export interface IGasSchema {
  capacity: number | null;
  pressureType: TPressureType | null;
  locationType: TLocationType | null;
  possibleToConnect: boolean | null;
  type: TGasType | null;
}

export type TInputType = 'commonFromStreet' | 'commonFromYard' | 'separateFromStreet' | 'separateFromYard';

export type TAreaUnitType = 'sotka' | 'hectare';

export type TLandType = 'owned' | 'rent';

export type TLandStatus =
  | 'individualHousingConstruction'
  | 'gardening'
  | 'farm'
  | 'suburbanNonProfitPartnership'
  | 'industrialLand'
  | 'gardeningNonProfitPartnership'
  | 'suburbanNonProfitSettlementsPartnership'
  | 'forAgriculturalPurposes'
  | 'industryTransportCommunications'
  | 'ofProtectedCategories'
  | 'forestArea'
  | 'waterArea'
  | 'reserve'
  | 'investmentProject'
  | 'settlements'
  | 'privateFarm';

enum ELandCategory {
  /** Земли сельскохозяйственного назначения */
  ForAgriculturalPurposes = 'forAgriculturalPurposes',
  /** Земли населенных пунктов */
  Settlements = 'settlements',
  /** Иное (промышленности, лесного и водного фондов, земли запаса) */
  Other = 'other',
}

export interface ILandSchema {
  area: number;
  areaUnitType: TAreaUnitType | null;
  possibleToChangeStatus: boolean | null;
  status: TLandStatus | null;
  type: TLandType | null;
  /** Категория земель */
  landCategory?: ELandCategory;
}

export type TLayout = 'cabinet' | 'corridorplan' | 'mixed' | 'openSpace';

export interface IOfferNewbuildingHouseFinishDate {
  quarter: number;
  year: number;
}

export interface INewBuildingHouseSchema {
  isReliable: boolean;
  isFinished: boolean;
  finishDate?: IOfferNewbuildingHouseFinishDate;
  id: number;
  name: string;
}

export interface INewBuildingSchema {
  isFromDeveloper: boolean;
  name: string;
  isFromBuilder: boolean;
  isFromSeller: boolean;
  isFromLeadFactory: boolean;
  isReliable: boolean;
  showJkReliableFlag?: boolean;
  id: number;
  house?: INewBuildingHouseSchema;
  isPremium: boolean;
}

export interface IPhoneSchema {
  countryCode: string | null;
  number: string | null;
}

export interface IPhotoSchema {
  fullUrl: string;
  id: number;
  isDefault: boolean;
  isLayout: boolean;
  isCianLayout?: boolean;
  rotateDegree: number;
  thumbnailUrl: string;
  description?: string;
}

export type TRepair = 'cosmetic' | 'euro' | 'design' | 'no';

export type TPlacement = 'shoppingMall' | 'streetRetail';

export type TRoomType = 'separate' | 'combined' | 'both';

export interface ISpecialtySchema {
  engName: string | null;
  id: number | null;
  rusName: string | null;
}

export type TOfferStatus =
  | 'blocked'
  | 'deactivated'
  | 'deleted'
  | 'draft'
  | 'moderate'
  | 'published'
  | 'refused'
  | 'removedByModerator'
  | 'sold';

export interface ICommercialSpecialtySchema {
  additionalTypes: string[] | null;
  specialties: ISpecialtySchema[] | null;
  types: string[];
}

export interface ITrackingDataSchema {
  moId: number | null;
  oblId: number | null;
  cityId: number | null;
  fbRegion: string | null;
  fbCity: string | null;
}

export interface IValueAddedServicesSchema {
  isAuction: boolean;
  isColorized: boolean;
  isStandard: boolean;
  isPremium: boolean;
  isTop3: boolean;
}

export interface IVideoSchema {
  id: string;
  previewUrl?: string;
  type: EVideoType;
  url: string;
}

export enum EVideoType {
  'Kinescope' = 'kinescope',
  'Youtube' = 'youtube',
  'Vk' = 'vk',
}

export type TWaterType = 'water_intake_facility' | 'water_tower' | 'pumping_station' | 'central' | 'autonomous';

export type TSuburbanWaterType = 'borehole' | 'central' | 'well';

export interface IWaterSchema {
  locationType: TLocationType | null;
  type: TWaterType | null;
  suburbanWaterType: TSuburbanWaterType | null;
  capacity: number | null;
  possibleToConnect: boolean | null;
}

export type TWindowsView = 'yard' | 'street' | 'yardAndStreet';

export type TWcLocation = 'indoors' | 'outdoors';

export type TWсType = 'separate' | 'combined';

export interface IWorkTimeInfoSchema {
  timezoneText: string;
  callFrom: string;
  callTo: string;
  isAvailable: boolean;
}

export interface IOfferStatsSchema {
  daily: number;
  total: number;
  totalUniqueViewsFormattedString?: string;
  totalViewsFormattedString?: string;
}

export interface IPriceChangeSchema {
  changeTime: string;
  priceData: IPriceDataSchema;
}

export interface IPriceDataSchema {
  paymentPeriod: TPaymentPeriod;
  currency: TCurrency;
  price: number;
}

export interface ISeoLinkSchema {
  title: string | null;
  url: string | null;
}

export interface ISocialNetworksTitleSchema {
  full: string;
  short: string;
}

export interface IMetaTagsSchema {
  description: string;
  keywords: string;
  title: string;
}

export interface ISeoDataSchema {
  description: string;
  mainTitle: string;
  micromarking: object;
  socialNetworksTitle: ISocialNetworksTitleSchema;
  autogeneratedDescriptions?: IMetaTagsSchema;
}

export interface IUndergroundInfoNewObjectSchema {
  lineColor: string | null;
  name: string | null;
  type: string | null;
}

export interface INearbyNewbuildingOfferSchema {
  addressStr: string | null;
  floor: number | null;
  floorn: number | null;
  id: number | null;
  kitchenArea: string | null;
  livingArea: string | null;
  photoUrl: string | null;
  priceRur: string | null;
  roomTitle: string | null;
  totalArea: string | null;
  undergroundInfo: IUndergroundInfoNewObjectSchema | null;
  ownerId: number | null;
  publishedUserId: number | null;
}

export interface ISpecialProjectItemSchema {
  id: string;
  link: string;
}

export interface IUserSubscriptionSchema {
  isSubscribed: boolean;
  email: string | null;
}

export interface IUserGASchema {
  abGroup: number | null;
  accountType: TAccountType | null;
  balance: number | null;
  emailMd5: string | null;
  packages: string[] | null;
  type: string | null;
}

export interface IUserPhoneSchema {
  confirmed: boolean | null;
  phone: string | null;
}

export interface IUserPermissionSchema {
  canModerateAnnouncements: boolean | null;
  canModerateAnnouncementsExpert: boolean | null;
  canModerateUsers: boolean | null;
  canViewAnnouncements: boolean | null;
  canViewUsers: boolean | null;
}

export interface IUserSchema {
  cianName: string | null;
  cianUserId: number | null;
  companyName: string;
  criteoEmail: string | null;
  email: string | null;
  firstName: string | null;
  ga: IUserGASchema | null;
  isAgent: boolean | null;
  isAuthenticated: boolean | null;
  lastName: string | null;
  permissions: IUserPermissionSchema | null;
  phones: IUserPhoneSchema[] | null;
  realtyUserId: number | null;
  isAgentAvailabilityEnabled?: boolean;
  isProfi?: boolean;
}

export interface IAgentAvailabilitySchema {
  /** Доступен ли агент в данный промежуток времени в таймзоне агента */
  available: boolean | null;
  /** тут пользователь - автор объявления */
  userId: string | null;
  /** Заголовок с текстом для не рабочего времени в таймзоне +3 */
  title: string | null;
  /** Сообщение с текстом для не рабочего времени в таймзоне +3 */
  message: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableFrom: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  availableTo: string | null;
  /** Дата и Время в формате ISO8601 c указанием таймзоны агента */
  vacationTo: string | null;
}

export interface ICountrysidePromoItem {
  type: 'sale' | 'mortgage' | 'bonus';
  id: number;
  name: string;
  label: string;
  endDate: string;
  description?: string;
}

export interface IKPSchema {
  id: number;
  name: string;
  /** Включено ли отображение номера телефона у застройщика */
  displayBuilderPhone: boolean;
  /** ЧПУ урл на карточку КП */
  url: string;
  /* Эксперимент countryside_card_integration */
  imageUrl: string;
  villageClass: TVillageClass;
  counterSet: IKPCounterSetSchema;
  promotions: ICountrysidePromoItem[] | null;
  builderId: number;
  locationId: number;
}

export interface IContractorForSliderSchema {
  id: number;
  logoUrl: string;
  name: string;
}

export interface IProjectForSliderSchema {
  id: number;
  name: string;
  style?: string;
  type?: string;
  constructionType?: string;
  area: number;
  priceMin: number;
  priceMax: number;
  imageUrl: string;
  url: string;
  contractor: IContractorForSliderSchema;
}

export interface ICountrysideProjectsSchema {
  areProjectsHidden: boolean;
  projects: IProjectForSliderSchema[];
}

export type TVillageClass = 'economy' | 'comfort' | 'business' | 'elite';

export interface IKPCounterSetSchema {
  onSale: number;
  sold: number;
  total: number;
  underConstruction: number;
}

enum EHouseCondition {
  /** Без внутренней отделки */
  InteriorDecorationRequired = 'interiorDecorationRequired',
  /** Требует капитального ремонта или под снос */
  MajorRepairsRequired = 'majorRepairsRequired',
  /** Готов к проживанию */
  Ready = 'ready',
  /** Недостроенный */
  Unfinished = 'unfinished',
}

export interface IHouseSchema {
  /** Состояние дома */
  condition?: EHouseCondition;
  /** Есть терраса */
  hasTerrace?: true;
  /** Есть погреб */
  hasCellar?: true;
}
