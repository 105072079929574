import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { openChatbot } from 'shared/actions/chat';
import { useExpertOptionEnabled } from 'shared/hooks/useExpertOptionEnabled';
import { selectOfferId } from 'shared/selectors';

import { trackClickExpertOptionButton, trackShowExpertOption } from './tracking';
import { ExpertOpinionButton } from '../../components/ExpertOpinionButton/ExpertOpinionButton';

export function ExpertOpinionButtonContainer() {
  const dispatch = useDispatch();
  const offerId = useSelector(selectOfferId);
  const expertOpinionButtonEnabled = useExpertOptionEnabled('B');

  React.useEffect(() => {
    if (expertOpinionButtonEnabled) {
      trackShowExpertOption(offerId);
    }
  }, [offerId, expertOpinionButtonEnabled]);

  const handleClickExpertButton = React.useCallback(async () => {
    trackClickExpertOptionButton(offerId);
    dispatch(openChatbot({ defaultMessage: 'Какие у этой квартиры плюсы и минусы?' }));
  }, [dispatch, offerId]);

  if (!expertOpinionButtonEnabled) {
    return null;
  }

  return <ExpertOpinionButton onClick={handleClickExpertButton} />;
}
