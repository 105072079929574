import { IconProductSpecialLoanRate24 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { IPromoBank } from 'shared/types/newbuildingPromos';

import * as styles from './BankIcon.css';

interface IBankIconProps {
  bank?: IPromoBank | null;
}

export function BankIcon({ bank }: IBankIconProps) {
  const bankLogo = bank?.secondSquareImageUrl || bank?.logoUrl;

  if (bankLogo) {
    return <img className={styles['logo']} src={bankLogo} alt="" />;
  }

  return (
    <div className={styles['icon']}>
      <IconProductSpecialLoanRate24 />
    </div>
  );
}
