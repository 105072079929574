import { Image } from '@cian/ui-kit';
import * as React from 'react';

import { ActionIcon } from './ActionIcon';
import { MaternalCapitalIcon } from './MaternalCapitalIcon';
import { MilitaryMortgageIcon } from './MilitaryMortgageIcon';
import { MortgageIcon } from './MortgageIcon';
import discountLogo from './assets/discount.png';
import { EPromoType } from '../../../../../types/newbuildingPromos';

export function getPromoIcon(type: EPromoType): React.ReactElement | null {
  switch (type) {
    case EPromoType.Discount:
      return <Image src={discountLogo} alt="logo" objectFit="contain" preloader={false} />;
    case EPromoType.CianMortgage:
    case EPromoType.Mortgage:
      return <MortgageIcon />;
    case EPromoType.Bonus:
      return <ActionIcon />;
    case EPromoType.MilitaryMortgage:
      return <MilitaryMortgageIcon />;
    case EPromoType.MaternalCapital:
      return <MaternalCapitalIcon />;
    default:
      return null;
  }
}
