import { ErrorLogComponent } from '@cian/error-log-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { CommercialPublishersBadges } from 'shared/components/CommercialPublishersBadges';
import { NewbuildingBlackFridayPromo } from 'shared/components/NewbuildingBlackFridayPromo';
import { PriceAuctionContainer } from 'shared/components/PriceAuction';
import { NewbuildingPremiumBuilderInfo } from 'shared/containers/AuthorAsideContainerNew/parts';
import { BookingCalendarInputContainer } from 'shared/containers/BookingCalendarInputContainer';
import { BookingSummaryContainer } from 'shared/containers/BookingSummaryContainer';
import { AsideBuyAnalyticsBlockContainer } from 'shared/containers/BuyAnalyticsBlocks';
import { GuestsInputContainer } from 'shared/containers/GuestsInputContainer';
import { MainPromo } from 'shared/containers/MainPromo';
import { useShowDailyrentBookingCalendar } from 'shared/hooks/useShowDailyrentBookingCalendar';
import { selectBestPlaceAnalyticsAvailable } from 'shared/selectors/bestPlaceAnalytics';
import { selectBuyAnalyticsReportAvailability } from 'shared/selectors/buyAnalyticsModal';
import { selectDailyrentActionType } from 'shared/selectors/dailyrentActionType';
import { selectIsMortgageBrokerBannerAvailable } from 'shared/selectors/mortgageBroker';
import { selectBlackFridayLabel } from 'shared/selectors/offerData/offer/blackFriday';
import { isCommercialSelector } from 'shared/selectors/offerData/offer/isCommercialSelector';
import { isPriceElectronicTradingSelector } from 'shared/selectors/price';
import { EActionType } from 'shared/types/dailyrentActionButtonsType';
import { useIsDailyrentOnlineBookingEnabled } from 'shared/utils/featureToggles/useIsDailyrentOnlineBookingEnabled';

import { PriceContainer } from './Price';
import { PriceChangesContainer } from './PriceChanges';
import { AsideGroup } from '../../components/AsideSection/AsideGroup';
import { AsideItem } from '../../components/AsideSection/AsideItem';
import { AsideSection } from '../../components/AsideSection/AsideSection';
import { DealRent } from '../../components/DealRent';
import { OfferCardAsideContainer } from '../../components/aside/container';
import { AsideBanners } from '../../components/aside_banners/AsideBanners';
import { isDealRent as isDealRentSelector } from '../../selectors/isDealRent';
import { selectIsMortgageBannerEnabledIgnoreAgent } from '../../selectors/mortgage/selectIsMortgageBannerEnabled';
import { selectIsNewbuildingPremiumRate } from '../../selectors/newbuildingPremiumRate';
import { useApplicationContext } from '../ApplicationContext';
import { AsideContactsContainer } from '../AsideContacts/AsideContacts';
import { PhonesOpenedProvider } from '../AsideContacts/providers/PhonesOpened';
import { AuthorAsideContainer as AuthorAsideContainerNew } from '../AuthorAsideContainerNew';
import { ExpertOpinionButtonContainer } from '../ExpertOpinionButtonContainer';
import { FavoriteButtonContainer } from '../FavoriteButtonContainer';
import { MortgageSidebarBannerContainer } from '../MortgageSidebarBannerContainer';
import { MortgageWidgetContainer } from '../MortgageWidgetRedesignContainer';
import { NewbuildingBuildersFactsContainer } from '../NewbuildingBuildersFactsContainer';
import { NewbuildingRatingContainer } from '../NewbuildingRatingContainer';
import { OfferFactsInSidebarContainer } from '../OfferFactsInSidebarContainer';
import { OfferTitleSidebarContainer } from '../OfferTitleSidebarContainer';
import { PriceRequestContainer } from '../PriceRequestContainer';
import { RentBlockContainer } from '../RentBlockContainer';
import { AgencyBrandingAsideCardContainer } from '../agencyBranding/AgencyBrandingAsideCard';

export const AsideRedesignedContainer = () => {
  const { logger } = useApplicationContext();
  const isDealRent = useSelector(isDealRentSelector);
  const isCommercial = useSelector(isCommercialSelector);
  const isPremiumNewbuilding = useSelector(selectIsNewbuildingPremiumRate);
  const isMortgageEnabled = useSelector(selectIsMortgageBannerEnabledIgnoreAgent);
  const isBuyAnalyticsButtonEnabled = useSelector(selectBuyAnalyticsReportAvailability);
  const isBestPlaceAnalyticsDisplayed = useSelector(selectBestPlaceAnalyticsAvailable);
  const isCreSpaceAnalyticsDisplayed = !isBestPlaceAnalyticsDisplayed && isBuyAnalyticsButtonEnabled;
  const isPriceElectronicTrading = useSelector(isPriceElectronicTradingSelector);
  const blackFridayLabel = useSelector(selectBlackFridayLabel);
  const isMortgageBrokerBannerAvailable = useSelector(selectIsMortgageBrokerBannerAvailable);
  const actionType = useSelector(selectDailyrentActionType);

  const renderDailyrentBookingCalendar = useShowDailyrentBookingCalendar();
  const isDailyrentOnlineBookingEnabled = useIsDailyrentOnlineBookingEnabled();
  const showBookingSummaryAndGuestsInput =
    isDailyrentOnlineBookingEnabled &&
    actionType &&
    [EActionType.OtaBooking, EActionType.OtaCheckDates].includes(actionType);

  return (
    <OfferCardAsideContainer>
      <ExpertOpinionButtonContainer />
      <AsideSection dataName="AsideMainInfo" withBorders={false}>
        <PhonesOpenedProvider>
          <AsideGroup>
            {isPremiumNewbuilding && (
              <AsideItem>
                <NewbuildingRatingContainer />
                <OfferTitleSidebarContainer />
              </AsideItem>
            )}
            <AsideItem>
              <PriceAuctionContainer />
            </AsideItem>
            <AsideItem isRedesign={true}>
              <PriceContainer />
              <FavoriteButtonContainer newButton={true} />
            </AsideItem>
            {isCommercial && (
              <AsideItem>
                <CommercialPublishersBadges />
              </AsideItem>
            )}
            {!isPriceElectronicTrading && !isPremiumNewbuilding && (
              <AsideItem>
                <PriceChangesContainer />
              </AsideItem>
            )}
            {renderDailyrentBookingCalendar && (
              <AsideItem>
                <BookingCalendarInputContainer />
              </AsideItem>
            )}
            {showBookingSummaryAndGuestsInput && (
              <AsideItem>
                <GuestsInputContainer />
              </AsideItem>
            )}
            {showBookingSummaryAndGuestsInput && (
              <AsideItem>
                <BookingSummaryContainer />
              </AsideItem>
            )}
            <AsideItem>{<PriceRequestContainer />}</AsideItem>
          </AsideGroup>
          <AsideGroup>
            <AsideItem>
              <MainPromo />
            </AsideItem>
            <AsideItem>
              {blackFridayLabel ? (
                <NewbuildingBlackFridayPromo text={blackFridayLabel} />
              ) : (
                isMortgageEnabled && <MortgageWidgetContainer />
              )}
            </AsideItem>
          </AsideGroup>
          <AsideGroup>
            <AsideItem>
              <OfferFactsInSidebarContainer />
            </AsideItem>
          </AsideGroup>
          <AsideContactsContainer />
        </PhonesOpenedProvider>
      </AsideSection>
      <AsideSection dataName="AuthorAside" withBorders={false} overflowed>
        <AuthorAsideContainerNew />
      </AsideSection>
      <AsideSection dataName="AuthorBrandingAside" withSpaces={false} withBorders={false} overflowed>
        <NewbuildingPremiumBuilderInfo />
        <AgencyBrandingAsideCardContainer />
        <NewbuildingBuildersFactsContainer />
      </AsideSection>
      {isCreSpaceAnalyticsDisplayed && (
        <AsideSection withSpaces={false} withBorders={false} withShadow={false}>
          <AsideBuyAnalyticsBlockContainer />
        </AsideSection>
      )}

      {!isPremiumNewbuilding && (
        <AsideSection dataName="AsideAd" hideForPrint={true} withShadow={false} withBorders={false} withSpaces={false}>
          <ErrorLogComponent logger={logger}>
            <AsideBanners />
          </ErrorLogComponent>
        </AsideSection>
      )}

      <DealRent />
      {isDealRent && <RentBlockContainer />}

      {!isMortgageBrokerBannerAvailable && !isPremiumNewbuilding && !isCommercial && (
        <AsideSection dataName="AsideMortgageBanner" hideForPrint={true} withShadow={false}>
          <MortgageSidebarBannerContainer />
        </AsideSection>
      )}
    </OfferCardAsideContainer>
  );
};
