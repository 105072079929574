import { IState } from 'shared/store';

import { selectConsultantMessageButtonAvailable } from '../newbuildingConsultant';
import { selectIsChatsEnabled } from '../offerChat';
import { isNewbuildingFromDeveloperSelector } from '../offerData';

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
const selectBuilderChatOnboardingAvailable = (state: IState) => {
  const { isChatOnboardingEnabled } = state.featureToggle;
  const isChatsEnabled = selectIsChatsEnabled(state);
  const isNewbuildingFromDeveloper = isNewbuildingFromDeveloperSelector(state);

  return isChatOnboardingEnabled && isChatsEnabled && isNewbuildingFromDeveloper;
};

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
const selectConsultChatOnboardingAvailable = (state: IState) => {
  const { isWebimChatOnboardingEnabled } = state.featureToggle;
  const isConsultantMessageButtonAvailable = selectConsultantMessageButtonAvailable(state);

  return isWebimChatOnboardingEnabled && isConsultantMessageButtonAvailable;
};

// TODO: Удалить в CD-226224/CD-225475.Онбординги на месяц.
export const selectChatOnboardingAvailable = (state: IState) => {
  return selectBuilderChatOnboardingAvailable(state) || selectConsultChatOnboardingAvailable(state);
};
