import { Image, UIHeading5 } from '@cian/ui-kit';
import { IconActionArrowRight16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './ExpertOpinionButton.css';
import avatar from './assets/avatar.png';

export interface IExpertOpinionButtonProps {
  onClick: () => void;
}

export const ExpertOpinionButton = ({ onClick }: IExpertOpinionButtonProps) => {
  return (
    <div className={styles['container']}>
      <div role="button" className={styles['button']} data-testid="expert_opinion_button" onClick={onClick}>
        <Image width={28} height={28} borderRadius="4px" objectFit="cover" src={avatar} data-testid="image" />
        <UIHeading5 color="control-main-primary-default">{'Главное из объявления'}</UIHeading5>
        <IconActionArrowRight16 color="control-main-primary-default" />
      </div>
    </div>
  );
};
