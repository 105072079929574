import { ca } from '@cian/analytics';

interface ITrackBookingFormOpenParams {
  gaLabel: string;
  id: number;
}

export function trackBookingRequestSuccess({ gaLabel, id }: ITrackBookingFormOpenParams) {
  ca('eventSite', {
    action: 'CallBack_popup_send_floating_block',
    category: 'Card',
    label: gaLabel,
    name: 'oldevent',
    products: [{ id }],
  });
}

export function trackBookingRequestError({ gaLabel, id }: ITrackBookingFormOpenParams) {
  ca('eventSite', {
    action: 'CallBack_popup_error',
    category: 'Card',
    label: gaLabel,
    name: 'oldevent',
    products: [{ id }],
  });
}
