import { IconMapCar16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { ShowMoreButton } from 'shared/components/GeoRedesigned/ShowMoreButton';

import { useUndergrounds } from './hooks/useUndergrounds';
import { UndergroundItem } from '../../components/GeoRedesigned/UndergroundItem';
import { UndergroundList } from '../../components/GeoRedesigned/UndergroundList';
import { UndergroundIcon } from '../../components/UndergroundIcon';
import { regionIdSelector } from '../../selectors/offerData/geo/regionIdSelector';
import { isDefined } from '../../utils';
import { getConstructionInfo } from '../../utils/formatters';

export const UndergroundsContainer = () => {
  const regionId = useSelector(regionIdSelector);
  const { undergrounds, expandable, expanded, hiddenUndergroundsCount, toggleExpanded } = useUndergrounds();

  if (!undergrounds.length) {
    return null;
  }

  return (
    <UndergroundList>
      {undergrounds.map(underground => {
        const { underConstruction, lineColor, name, url, lineType } = underground;
        const constructionInfo = getConstructionInfo(underground);
        const travelTime = isDefined(underground.travelTime) ? `${underground.travelTime || '<1'} мин.` : null;
        const travelType = underground.travelType === 'walk' ? <IconMapWalk16 /> : <IconMapCar16 />;

        return (
          <UndergroundItem
            key={`underground_${name}`}
            icon={<UndergroundIcon color={`#${lineColor}`} regionId={regionId} lineType={lineType} />}
            underConstruction={!!underConstruction}
            undergroundName={name}
            undergroundUrl={url}
            constructionInfo={constructionInfo}
            travelType={travelType}
            travelTime={travelTime}
          />
        );
      })}
      {expandable && <ShowMoreButton onClick={toggleExpanded} expanded={expanded} count={hiddenUndergroundsCount} />}
    </UndergroundList>
  );
};
