import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { Button } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingBookingFormContainer } from 'shared/containers/NewbuildingBookingForm';
import { trackBookingButtonShown, trackBookingFormOpen } from 'shared/containers/NewbuildingBookingFormButton/tracking';
import { selectOfferId } from 'shared/selectors';
import { getGaLabel } from 'shared/selectors/newObject';
import { selectIsBookingFormEnabled } from 'shared/selectors/newbuildingBookingForm/selectIsBookingFormEnabled';

export const NewbuildingBookingFormButton = () => {
  const isBookingFormEnabled = useSelector(selectIsBookingFormEnabled);
  const gaLabel = useSelector(getGaLabel);
  const id = useSelector(selectOfferId);

  const [opened, setOpened] = React.useState(false);

  const onOpen = React.useCallback(() => {
    setOpened(true);
    trackBookingFormOpen({
      gaLabel,
      id,
    });
  }, [gaLabel, id]);

  const onClose = React.useCallback(() => {
    setOpened(false);
  }, []);

  const onShow = React.useCallback(() => {
    trackBookingButtonShown(id);
  }, [id]);

  if (!isBookingFormEnabled) {
    return null;
  }

  return (
    <>
      <ActionAfterViewObserver callback={onShow} triggerOnce wrapper={false}>
        <Button size="M" theme="fill_secondary" fullWidth onClick={onOpen} data-testid="BookingFormButton">
          Заявка на бронирование
        </Button>
      </ActionAfterViewObserver>
      <NewbuildingBookingFormContainer opened={opened} onClose={onClose} />
    </>
  );
};
