import { Button, Image } from '@cian/ui-kit';
import * as React from 'react';

import { ResultScreen } from 'shared/components/NewbuildingBookingForm/ResultScreen';

import imageSrc from './assets/success.png';

interface ISuccessScreenProps {
  onClose(): void;
}

export const SuccessScreen = ({ onClose }: ISuccessScreenProps) => {
  return (
    <ResultScreen
      title="Заявка отправлена!"
      subtitle="Скоро вам перезвонит менеджер для уточнения условий заявки"
      button={
        <Button theme="fill_primary" size="L" color="current_color" onClick={onClose}>
          Спасибо, жду
        </Button>
      }
      image={<Image src={imageSrc} width={100} height={100} objectFit="contain" preloader={false} />}
    />
  );
};
